import { useEffect, createContext, useState } from "react";
import { addDisconnectListener, addLoginAnalytics, analytics, auth, getCountDownData, getUserDetails } from "../../components/firebase";

export const UserContext = createContext({ showControls: false });

function UserContextProvider(props) {
  const [countdownData, setCountdownData] = useState({
    checking: true,
    show: false,
    time: "Jul 23, 2024 11:00:00"
});
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  useEffect(() => {

    getCountDownData((res)=>{
      if(res){
          setCountdownData((prev)=>({checking:false, ...res}))
      } else{
          setCountdownData((prev)=>({checking:false, ...prev})) 
      }
  });

    auth.onAuthStateChanged(async (userAuth) => {
      try {
        if (userAuth) {
          let user = {
            ...userAuth,
            name: userAuth.displayName,
            email: userAuth.email,
            uid: userAuth.uid,
          };
          setUser(user);
          localStorage.setItem("user", JSON.stringify(user));
          let res = await getUserDetails(userAuth.uid);
          localStorage.setItem("userDetails", JSON.stringify(res));
          analytics.setUserId(user.uid);
          analytics.setUserProperties(res);
          addLoginAnalytics(user);
          addDisconnectListener(user);
          setUserDetails(res);
        } else {
          analytics.setUserId(null);
          analytics.setUserProperties(null);
          sessionStorage.clear();
          localStorage.clear();
          setUser(null);
          // setUserDetails(null);
        }
      } catch (error) {
        console.log(error, "error");
      }
    });
  }, []);
  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        userDetails,
        setUserDetails,
        countdownData
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;
