import { createContext, useEffect, useState } from "react";
import Controls from "../../components/Controls";



const ControlsContext = createContext();

function ControlsProvider(props) {
    const [showControls, setShowControls] = useState(true);
    const toggleControls = () => setShowControls(!showControls);
    const toggleControlsManual = (val = true) => setShowControls(val);

    return (
        <ControlsContext.Provider
            value={{
                showControls,
                toggleControls,
                toggleControlsManual
            }}
        >
            <Controls showControls={showControls} toggleControls={toggleControls}>
                {props.children}
            </Controls>

        </ControlsContext.Provider>
    );
}
export default ControlsProvider;
