export const CurseWords = [
    "bc",
    "bsdk",
    "bhenchod",
    "saala",
    "saale",
    "mc",
    "madarchod",
    "lund",
    "chutiya",
    "boobs",
    "asshole",
    "ass",
    "kamina",
    "kutte",
    "kutta",
  ];
  