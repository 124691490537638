import React, { useContext, useReducer, useRef } from 'react'
import styles from "./index.module.scss"
import CustomButton from '../../components/CustomButton'
import { useNavigate } from 'react-router-dom'
import { signInWithId } from '../../components/firebase'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react'
// import FullScreenVideoController from '../../components/FullScreenVideoController'
// import promoVideo from "../../assets/videos/logos.mp4"
// import { isMobile } from 'react-device-detect'
import { UserContext } from '../../Contexts/UserContext/UserContextProvider'
import Countdown from '../../components/Countdown'



const inputs = [
    {
        name: "email",
        label: "Email",
        type: "text"
    },
]


const defaultErr = {
    email: "",
}


const initialStates = {
    email: "",
    err: { ...defaultErr }
};

const ACTION_TYPES = {
    handleInputChange: "handleInputChange",
    handleError: "handleError",
    loading: "loading"
};

const reducer = (state, action) => {
    let { type, payload } = action;
    switch (type) {
        case ACTION_TYPES.handleInputChange:
            return {
                ...state,
                ...payload,
            };
        case ACTION_TYPES.handleError:
            return {
                ...state,
                ...payload,
            };
        case ACTION_TYPES.loading:
            return {
                ...state,
                ...payload,
            };
        default:
            return { ...state };
    }
};


const Login = () => {
    const { countdownData } = useContext(UserContext)
    const navigation = useNavigate()
    const [state, dispatch] = useReducer(reducer, initialStates);
    const isTriedSubmitting = useRef(false)
    const [isLoading, setIsLoading] = useState(false)


    const validateEmail = (email) => {
        const re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
    const validateForm = () => {

        let errors = state.err;
        errors.email = state.email.trim().length > 0 && validateEmail(state.email) ? "" : "Please enter valid email.";

        dispatch({
            type: ACTION_TYPES.handleError,
            payload: { err: { ...errors } },
        });
    };


    const handleInputChange = (e) => {
        if (isTriedSubmitting.current) {
            validateForm();

        }
        dispatch({
            type: ACTION_TYPES.handleInputChange,
            payload: { [e.target.name]: e.target.value }
        })
    }

    const isValidForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
        return valid;
    };

    const handleBack = () => {
        navigation(-1)
    }

    const handleLogin = () => {
        isTriedSubmitting.current = true
        setIsLoading(true)
        validateForm();
        if (!isValidForm(state.err)) {
            dispatch({
                type: ACTION_TYPES.loading,
                payload: { loading: false },
            });
            setIsLoading(false)
            return;
        }
        const { email } = state
        signInWithId(email.toLowerCase()).then(res => {
            if (res) {
                try {
                    setIsLoading(false)

                    navigation("/welcome")
                }
                catch (err) {
                    setIsLoading(false)

                    console.log(err, "Err")
                }
            } else {
                setIsLoading(false)

                toast.error('Validation Error', {
                    position: "bottom-right",
                    autoClose: 5000,
                    zIndex: 1000,
                    hideProgressBar: false,
                    error: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }).catch(err => {
            setIsLoading(false)

            toast.error('No User Found', {
                position: "bottom-right",
                autoClose: 5000,
                zIndex: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                error: true,
                progress: undefined,
                theme: "colored",
            });
        })
    }


    const handleRegister = () => {

        navigation("/register")
    }

    return (
        <div className={`${styles.LoginWrapper} responsiveHeight`}>
            {/* {!isMobile && <div className={styles.sideVideo}>
                <FullScreenVideoController logos={true} url={promoVideo} />
            </div>} */}
            {countdownData.checking ?
                <div className={"mainLoader"}></div>
                : countdownData.show ?
                    <div className={styles.loginContainer}>
                        <Countdown data={countdownData} /></div> :
            <div className={styles.loginContainer}>
                <div onClick={handleBack} className={`${styles.backBtn} animate__animated animate__fadeIn`}><svg viewBox="0 0" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.2969 23.4375L5.85938 15L14.2969 6.5625M7.03125 15H24.1406" stroke="white" strokeWidth="2.1875" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                </div>
                <div className={styles.loginContent}>
                    <div className={`${styles.title} animate__animated animate__fadeIn`}>Log In</div>
                    <div className={styles.inputWrapper}>
                        {inputs.map((input,index) =>
                            <div key={index} className={styles.inputBox}>
                                <input name={input.name} onChange={handleInputChange} value={state[input.name]} type={input.type} placeholder={input.label} className={`${styles.input} animate__animated animate__fadeIn`} />
                                {state.err[input.name].length > 0 && <div className={styles.error}>{state.err[input.name]}</div>}
                            </div>
                        )}
                    </div>
                    <ToastContainer />
                    <CustomButton isLoading={isLoading} onClick={handleLogin}>Login</CustomButton>
                </div>
                <div className={`${styles.bottomText} animate__animated animate__fadeIn`}>New User ? <span onClick={handleRegister}>Register</span></div>
            </div>}
        </div>

    )
}

export default Login