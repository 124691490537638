import React, { useState } from 'react'
import PROMOVIDEO from "../../assets/videos/tunnel.mp4"
// import INTROVIDEO from "../../assets/videos/KIA_Booth.mp4"
import { useNavigate } from 'react-router'
import FullScreenVideoController from '../../components/FullScreenVideoController'
import Isometric from '../../components/Isometric'
import styles from "./index.module.scss"
import { useRef } from 'react'
import { useEffect } from 'react'

const SCENE_NAME = {
    'promo': 0,
    "promoTransition": 1,
    'isometricView': 2,

    'introVideo': 2
}

let selectedZoneFromIsometricMap = null;
export default function IsometricSection() {
    const navigation = useNavigate();
    const overlayRef = useRef(null)
    const timerRef = useRef(null)
    const [currentScene, setCurrentScene] = useState(SCENE_NAME.promo)

    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current)
            }
        }
    }, [])
    useEffect(() => {
        if (overlayRef.current && currentScene == SCENE_NAME.promoTransition) {
            if (timerRef.current) {
                clearInterval(timerRef.current)
            }
            overlayRef.current.style.pointerEvents = "all";
            let progress = 0
            let reverse = false
            timerRef.current = setInterval(() => {
                if (progress >= 1 && !reverse) {
                    setCurrentScene(SCENE_NAME.isometricView)
                    reverse = true
                } else if (reverse && progress != 0) {
                    progress = progress - 0.01
                    overlayRef.current.style.opacity = progress
                } else {
                    progress = progress + 0.01
                    overlayRef.current.style.opacity = progress
                }
                if (progress <= 0 && reverse) {
                    overlayRef.current.style.pointerEvents = "none";
                    clearInterval(timerRef.current)
                }
            }, [5])

        }
        return () => {
            // if (timerRef.current) {
            //     clearInterval(timerRef.current)
            // }
        }

    }, [currentScene])

    return (
        <>
            {
                (currentScene == SCENE_NAME.promo || currentScene == SCENE_NAME.promoTransition) &&
                <FullScreenVideoController url={PROMOVIDEO} onComplete={() => setCurrentScene(SCENE_NAME.promoTransition)} />
            }

            {
                currentScene == SCENE_NAME.isometricView &&
                <Isometric onComplete={(currentZone) => {
                    selectedZoneFromIsometricMap = currentZone;
                    //TODO: get starting point
                    setCurrentScene(SCENE_NAME.introVideo);
                }
                } />
            }
            <div ref={overlayRef} className={styles.overlayScreen}></div>
            {/* {
                currentScene == SCENE_NAME.introVideo &&
                <FullScreenVideoController url={INTROVIDEO} onComplete={() => {
                    navigation(`/${ROUTES_CONSTANT.home}${selectedZoneFromIsometricMap.panoromaId}`)
                }} />
            } */}
        </>
    )
}
