import React, { useEffect, useRef, useState } from 'react'
import { zonesData } from '../data'
import styles from './index.module.scss'
import { LocationContext } from '../../../Contexts/LocationProvider';
import { useContext } from 'react';

export default function ZoneList({ setzoneList, isHome = false, setCurrentZone, currentZone }) {
    const scrollableDiv = useRef(null);
    const [showArrow, setShowArrow] = useState(false)
    const { currentLocation } = useContext(LocationContext)

    useEffect(() => {
        if (currentLocation != -1) {
            let _zone = null;
            zonesData.map(zone => {
                if (zone.id === currentLocation) {
                    _zone = zone
                }
            })
            if (_zone) {
                setCurrentZone(_zone)
            }
        }
    }, [currentLocation])


    useEffect(() => {
        const setFade = () => {
            if (scrollableDiv.current) {
                const { scrollHeight, clientHeight, scrollTop } = scrollableDiv.current;
                if (scrollHeight > clientHeight && scrollTop < (scrollHeight - clientHeight)) {
                    setShowArrow(true); // Show arrow if content overflows and user can scroll more
                } else {
                    setShowArrow(false); // Hide arrow if no more content to scroll
                }
            }
        };

        if (scrollableDiv.current) {
            scrollableDiv.current.addEventListener('scroll', setFade);
            window.addEventListener('resize', setFade); // Check on resize as well
        }

        // Initial check
        setFade();

        return () => {
            if (scrollableDiv.current) {
                scrollableDiv.current.removeEventListener('scroll', setFade);
            }
            window.removeEventListener('resize', setFade);
        };
    }, []);

    const handleClose = () => {
        setzoneList(false)
    }

    return (
        <>
            {isHome && <div className={styles.closeList}>
                <svg onClick={handleClose} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_b_94_698)">
                        <circle cx="18" cy="17.9998" r="18" fill="#182033" fillOpacity="0.8" />
                        <circle cx="18" cy="17.9998" r="17.75" stroke="white" strokeWidth="0.5" />
                    </g>
                    <path d="M14.3779 9.41904C14.6298 9.13952 14.9504 8.99976 15.3397 8.99976C15.729 8.99976 16.0496 9.13952 16.3015 9.41904L22.6221 16.4325C22.7595 16.585 22.8566 16.7501 22.9134 16.928C22.9702 17.1059 22.9991 17.2965 23 17.4998C23 17.703 22.9656 17.9002 22.8969 18.0913C22.8282 18.2824 22.7366 18.441 22.6221 18.567L16.3015 25.5805C16.0496 25.86 15.729 25.9998 15.3397 25.9998C14.9504 25.9998 14.6298 25.86 14.3779 25.5805C14.126 25.3009 14 24.9452 14 24.5132C14 24.0812 14.126 23.7255 14.3779 23.4459L19.7366 17.4998L14.3779 11.5536C14.126 11.274 14 10.9183 14 10.4863C14 10.0543 14.126 9.69856 14.3779 9.41904Z" fill="white" />
                    <defs>
                        <filter id="filter0_b_94_698" x="-24" y="-24.0002" width="84" height="84" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_94_698" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_94_698" result="shape" />
                        </filter>
                    </defs>
                </svg>
            </div>}
            <div className={`${styles.zoneContainer} ${isHome && styles.homeZone} responsiveHeight`}>

                <h2>Themes</h2>
                <div ref={scrollableDiv} className={styles.zonelist}>
                    <ul>
                        {
                            zonesData.map((zone, index) => (
                                <li
                                    key={index}
                                    onClick={() => {
                                        setCurrentZone(zone)
                                    }}
                                    className={`animate__animated animate__fadeInLeft ${zone.label == currentZone.label ? styles.selected : ''}`}
                                    style={{ animationDelay: `${0.15 * index}s` }}
                                >
                                    {
                                        zone.label
                                    }
                                    {zone.label == currentZone.label && <svg viewBox="0 0" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.316C-0.333332 9.54624 -0.333334 7.62173 1 6.85193L10.75 1.22277C12.0833 0.45297 13.75 1.41522 13.75 2.95482L13.75 14.2131C13.75 15.7527 12.0833 16.715 10.75 15.9452L1 10.316Z" fill="white" />
                                    </svg>
                                    }
                                </li>
                            ))
                        }
                    </ul>
                </div>
                {
                    showArrow &&
                    <div className={`${styles.downArrow} animate__infinite	infinite animate__animated animate__fadeInDown animate__delay-2s`}>
                        <div >
                        </div>
                        {/* Scroll */}
                    </div>
                }
            </div>
        </>
    )
}
