import { useState, useContext, useEffect } from "react";
import styles from "./index.module.scss";
import { addUserFeedback } from "../../firebase";
import greenLeafBg from "../../../assets/images/leaf/leafBGGreen.png";
import yellowLeafBg from "../../../assets/images/leaf/leafBGYellow.png";
import { Leaf_Type } from "..";
import { UserNameContainer } from "../SideDrawer";
import { UserContext } from "../../../Contexts/UserContext/UserContextProvider";
import { CloseBtnSvg } from "../../Svgs";
import profanity from "profanity-hindi";
import { CurseWords } from "../../../constants/CurseWords";

const FeedbackAddModal = ({ modalData, closeModal }) => {
  const { user } = useContext(UserContext);
  const { data, enable } = modalData;
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    profanity.addWords(CurseWords);
  }, []);
  const onFormSubmit = async (e) => {
    e.preventDefault();
    try {
      let _submittedValue = inputValue.trim();
      if (!_submittedValue || !user) {
        return;
      }
      let _data = {
        uid: user.uid,
        name: user.name,
        email: user.email,
        message: profanity.maskBadWords(_submittedValue),
      };
      setLoading(true);
      let res = await addUserFeedback(_data);
      if (res) {
        setLoading(false);
        setInputValue("");
        closeModal({
          ...data,
          ..._data,
          type: Leaf_Type.GREEN,
          timestamp: res,
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  let isGreenLeaf = data?.type === Leaf_Type.GREEN;
  return (
    <div
      className={`${styles.container} ${
        enable ? styles.modalFadeIn : styles.modalFadeOut
      }`}
    >
      <form
        className={enable ? styles.formFadeIn : styles.formFadeOut}
        onSubmit={onFormSubmit}
      >
        <div
          onClick={() => {
            setLoading(false);
            setInputValue("");
            closeModal();
          }}
          className={styles.closeBtn}
        >
          <CloseBtnSvg />
        </div>
        {data && (
          <>
            <img
              className={styles.leafImg}
              src={isGreenLeaf ? greenLeafBg : yellowLeafBg}
              alt=""
            />
            <div className={styles.textContainer}>
              {isGreenLeaf && (
                <UserNameContainer
                  data={{ name: data.name, timestamp: data.timestamp }}
                />
              )}
              <textarea
                data-gramm="false"
                data-gramm_editor="false"
                data-enable-grammarly="false"
                autoFocus={!isGreenLeaf}
                disabled={isGreenLeaf}
                value={!isGreenLeaf ? inputValue : data.message}
                onChange={(e) => setInputValue(e.target.value)}
                rows={10}
              />
            </div>
            {!isGreenLeaf && (
              <button
                disabled={loading || !inputValue}
                className={"treeButton"}
                type="submit"
              >
                <svg
                  width="24"
                  height="14"
                  viewBox="0 0 24 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-3.4226e-07 7.83L5.59 13.42L7 12L1.42 6.42M21.83 9.5422e-07L11.25 10.59L7.09 6.42L5.66 7.83L11.25 13.42L23.25 1.42M17.59 1.42L16.18 7.0725e-07L9.83 6.35L11.25 7.76L17.59 1.42Z"
                    fill="white"
                  />
                </svg>
                {loading ? "Loading..." : "Submit"}
              </button>
            )}
          </>
        )}
      </form>
    </div>
  );
};

export default FeedbackAddModal;
