import React from 'react'
import styles from "./index.module.scss"
import CustomButton from '../../components/CustomButton'
import { useNavigate } from 'react-router-dom'
import logo from "../../assets/images/title.png"
import { useContext } from 'react'
import { UserContext } from '../../Contexts/UserContext/UserContextProvider'
import { ROUTES_CONSTANT } from '../../constants/RoutesContstants'
import { addLoginAnalytics } from '../../components/firebase'
import FullScreenVideoController from '../../components/FullScreenVideoController'
import promoVideo from "../../assets/videos/logos.mp4"
import { isMobile } from 'react-device-detect'
import Countdown from '../../components/Countdown'

const MainScreen = () => {
    const { user, countdownData } = useContext(UserContext)
    const navigation = useNavigate();
    const handleRedirect = () => {
        if (user) {
            // addLoginAnalytics(user)
            navigation(`/${ROUTES_CONSTANT.welcome}`)
        } else {
            navigation(`/${ROUTES_CONSTANT.login}`)
        }
    }
    return (
        <div className={`${styles.MainScreenWrapper} responsiveHeight`}>
            {/* {!isMobile && <div className={styles.sideVideo}>
                <FullScreenVideoController logos={true} url={promoVideo} />
            </div>} */}
            {countdownData.checking ?
                <div className={"mainLoader"}></div>
                : countdownData.show ?
                    <div className={styles.mainContainer}>
                        <Countdown data={countdownData} /></div> :
                    <div className={styles.mainContainer}>
                        <div className={styles.titleContainer}>
                            <img src={logo} alt="" />
                            {/* <div className={styles.titleWrapper}>
                        <div className={styles.title}>Readdress
                        </div>
                    </div>
                    <div className={styles.titleWrapper}>
                        <div className={styles.title}>Return of </div>
                    </div>
                    <div className={styles.titleWrapper}>
                        <div className={styles.title}>Treasures</div>
                    </div> */}
                        </div>
                        <CustomButton onClick={handleRedirect} className={styles.nextBtn}>Continue</CustomButton>
                    </div>}
        </div>
    )
}

export default MainScreen