import { useCallback, useEffect, useState } from "react";
import { trimString } from "..";
import { CloseBtnSvg } from "../../Svgs";
import styles from "./index.module.scss";
import { getUserFeedbacksDataPaginated } from "../../firebase";

const descriptionLimit = 100;

const SideDrawer = ({ isOpen, closeSideBar }) => {
  const [data, setData] = useState({
    data: [],
    lastDocRef: { lastTimestamp: null, key: null },
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      getUserFeedbacksDataPaginated(
        { data: data.data, lastDocRef: data.lastDocRef },
        (res) => {
          if (res.data) {
            setLoading(false);
            setData({ data: res.data, lastDocRef: res.lastDocRef });
          }
        }
      );
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  return (
    <div className={`${styles["sidenav"]} ${isOpen ? styles.isOpen : ""}`}>
      <div className={styles.header}>
        <div className={styles.heading}>Review Archive</div>
        <div role="button" onClick={closeSideBar} className={styles.closeBtn}>
          <CloseBtnSvg />
        </div>
      </div>
      <div className={styles.main}>
        {loading && <div className={styles.noData}>Loading...</div>}
        {data.data.length === 0 && !loading && (
          <div className={styles.noData}>No Data</div>
        )}
        {data.data.map((feedback, index) => (
          <FeedBackCard key={index} data={feedback} />
        ))}
        {data.lastDocRef.lastTimestamp && (
          <div className={styles.footer}>
            <button
              onClick={getData}
              className={`treeButton ${styles.archiveButton}`}
            >
              View More
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SideDrawer;

const FeedBackCard = ({ data }) => {
  const { message, name, timestamp } = data;
  const [descriptionExpand, setDescriptionExpand] = useState(false);

  useEffect(() => {
    if (message.length > descriptionLimit) {
      setDescriptionExpand(true);
    }
  }, [message]);

  const handleMoreLessBtn = useCallback(() => {
    setDescriptionExpand((prev) => !prev);
  }, []);

  return (
    <div className={styles.card}>
      <UserNameContainer data={{ name: name, timestamp: timestamp }} />
      <div className={styles.description}>
        {!descriptionExpand ? message : trimString(message, descriptionLimit)}
        {message?.length > descriptionLimit && (
          <span className={styles.expandBtn} onClick={handleMoreLessBtn}>
            {!descriptionExpand ? " less" : " more"}
          </span>
        )}
      </div>
    </div>
  );
};

export const UserNameContainer = ({ data }) => {
  const { name, timestamp } = data;
  return (
    <div className={styles.upper}>
      <div className={styles.nameInitial}>{name ? name[0] : "NA"}</div>
      <div className={styles.nameContainer}>
        <div className={styles.name}>{name}</div>
        <div className={styles.date}>{new Date(timestamp).toDateString()}</div>
      </div>
    </div>
  );
};
