export const panoromaId = {
    tunnel: "#media-name=0002&yaw=-0.57&pitch=0.39",
    parrotlady: "#media-name=0043&yaw=96.59&pitch=-13.30&fov=110.00",
    dancingganesha: "#media-name=0004&yaw=51.70&pitch=-4&fov=200.00",
    historical: "#media-name=0004&yaw=-93.66&pitch=-1.79",
    conventionAndGuiding: '#media-name=0037&yaw=-81.82&pitch=-3.60&fov=110.00',
    bramhakiosk: "#media-name=0006&yaw=-89.59&pitch=8.01",
    specs: "#media-name=0016&yaw=19.77&pitch=-10.26&fov=110.00",
    amin: "#media-name=0039&yaw=9.01&pitch=-16.01&fov=110.00",
    holo: "#media-name=0027&yaw=174.35&pitch=11.20",
    tree: "#media-name=0026&yaw=-164.73&pitch=-6.26&fov=110.00&son=Video",
    three: "#media-name=0026&yaw=-169.42&pitch=-6.66",
    gormap: "#media-name=0012&yaw=49.82&pitch=-8.06",
    gorstatue1: "#media-name=0013&yaw=125.57&pitch=-8.89",
    gorstatue1: "#media-name=0014&yaw=123.25&pitch=-13.23",
    gorstatue3: "#media-name=0015&yaw=107.54&pitch=-3.40",
    gorstatue4: "#media-name=0016&yaw=155.78&pitch=-24.53",
    gorstatue5: "#media-name=0017&yaw=165.69&pitch=-24.53",
    gorstatue6: "#media-name=0018&yaw=176.60&pitch=-24.53",
    gorstatue7: "#media-name=0019&yaw=-178.74&pitch=-24.53",
    gorstatue8: "#media-name=0020&yaw=-173.88&pitch=-24.53",
    gostatue9: "#media-name=0021&yaw=-169.92&pitch=-24.53",
    gorpages1: "#media-name=0022&yaw=105.73&pitch=4.74",
    gorstatue10: "#media-name=0024&yaw=111.91&pitch=0.25",
    gorstatue11: "#media-name=0025&yaw=171.38&pitch=-28.56",
    gorvideo: "#media-name=0026&yaw=144.63&pitch=-17.61",
    // bs1: "bs1",
    bs2: "#media-name=0036&yaw=14.85&pitch=-37.03",
    bs3: "#media-name=0035&yaw=7.71&pitch=-37.03",
    bs4: "#media-name=0034&yaw=4.94&pitch=-37.03",
    bs5: "#media-name=0033&yaw=48.70&pitch=-45.27",
    bs6: "#media-name=0032&yaw=27.53&pitch=-45.27",
    bs7: "#media-name=0030&yaw=-4.66&pitch=-45.27",
    // bs8: "bs8",
    // bs9: "bs9",
    bp1: "#media-name=0031&yaw=3.83&pitch=-45.27",
    bp2: "#media-name=0029&yaw=-88.77&pitch=-24.38",
    // scroll: "scroll",
    g20wall: "#media-name=0002&yaw=-67.74&pitch=2.70",
    chvideo: "#media-name=0042&yaw=77.06&pitch=-21.20"
}