import React from 'react'
import styles from "./index.module.scss"
import touch from "../../assets/images/drag.png"
import navigate from "../../assets/images/navigate.png"

const OverlayTutorial = ({ progress }) => {
    return (
        <>
            <div className={styles.overlayFixed}></div>
            <div className={styles.overlayWrapper}>
                <div className={styles.heading}>Navigation Controls</div>
                <div className={styles.controlsWrapper}>
                    <div className={styles.controlBox}>
                        <div className={styles.icon}>
                            <img src={touch} alt='' />
                        </div>
                        <div className={styles.iconText}>Touch & Drag to explore</div>
                    </div>
                    <div className={styles.controlBox}>
                        <div className={styles.icon}>
                            <img src={navigate} alt='' />
                        </div>
                        <div className={styles.iconText}>Click on the floor to navigate</div>
                    </div>
                </div>
                <div className={styles.progressBox}>
                    <div className={styles.progressBar}>
                        <div
                            style={{
                                width: `${progress}%`,
                                maxWidth: "100%",
                                position: "absolute",
                                left: 0,
                                top: "-1px",
                                background: "#fff",
                                height: "10px",
                                borderRadius: "10px",
                                transition: "all .5s ease-in",
                            }}
                        ></div>
                    </div>
                    <h3 style={{ color: "#fff" }}>
                        Building your space. Please wait...
                    </h3>
                </div>
            </div >
        </>
    )
}

export default OverlayTutorial