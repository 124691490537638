import React, { useState, useRef, useEffect } from "react";
import styles from "./index.module.scss";
import PromoVideo from "../../assets/videos/KIA_Booth.mp4";
import loaderGIf from "../../assets/gif/loader.gif";
import CustomButton from "../CustomButton";
import { iOS } from "../AppWrapper";
import { isMobile } from "react-device-detect";

export default function FullScreenVideoController({
    logos = false,
    url = PromoVideo,
    onComplete = () => { },
}) {
    const videoRef = useRef(null);
    const [umuteText, setumuteText] = useState(false);

    const checkAutoPlay = (e) => {

        if (isMobile && (e.target.orientation == 90 || e.target.orientation == 270)) {
            if (videoRef.current) {

                videoRef.current.play().catch((e) => {
                    if (e.name == "NotAllowedError") {
                        videoRef.current.muted = true;
                        videoRef.current.play();
                    }
                });
                videoRef.current.play()
            }
        } else {
            if (videoRef.current) {
                videoRef.current.pause()
            }
        }
        // if (isMobile && (e.tar)) {
        //     alert("landscape")
        // }
        // else {
        //     videoRef.current.src = null
        // }
    }

    useEffect(() => {
        // if (!isMobile) {
        videoRef.current.play().catch((e) => {
            if (e.name == "NotAllowedError") {
                videoRef.current.muted = true;
                videoRef.current.play();
            }
        });
        // } else {
        //     checkAutoPlay(e)
        // }

        window.addEventListener("orientationchange", (e) => {
            checkAutoPlay(e)
        })
    }, [isMobile]);


    const handleVideoEnd = () => {
        onComplete();
    };

    return (
        <div style={{ width: logos ? "100%" : "100vw" }} className={`${styles.container} responsiveHeight`}>
            {/* <Header showMenu={false} /> */}
            {umuteText && !logos && (
                <p
                    className={styles.infoText}
                    onClick={() => {
                        videoRef.current.muted = false;
                        videoRef.current.volume = 1;
                        setumuteText(false);
                    }}
                >
                    CLICK TO UNMUTE
                </p>
            )}
            <img className={styles.loader} src={loaderGIf} alt={"loader"} />
            <video
                ref={videoRef}
                src={url}
                // autoPlay
                // controls
                loop={logos}
                playsInline
                muted={false}
                onPlay={() => {
                    if (videoRef.current.muted || videoRef.current.volume == 0) {
                        setumuteText(true);
                    } else {
                        setumuteText(false);
                    }
                }}
                onEnded={() => {
                    handleVideoEnd();
                }}
            // style={{
            //     display: 'none'
            // }}
            />
            {!logos && <div
                className={`${styles.btn} ${iOS() ? styles.btnIos : styles.btnAndroid
                    } animate__animated  animate__delay-2s animate__fadeInUp`}
            >
                <CustomButton
                    onClick={(e) => {
                        e.preventDefault();
                        handleVideoEnd();
                    }}
                >
                    Skip <SkipIcon />
                </CustomButton>
            </div>}
        </div>
    );
}

const SkipIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 22" fill="none">
        <path
            d="M19.4615 22V0H23V22H19.4615ZM0 22V0L15.9231 11L0 22ZM3.53846 15.125L9.55385 11L3.53846 6.875V15.125Z"
            fill="#06141F"
        />
    </svg>
);
