export const ROUTES_CONSTANT = {
    root: "/",
    login: "login",
    register: "register",
    welcome: "welcome",
    home: "home",
    quiz: "quiz",
    intro: "intro",
    connect: "connect",
    globalgame: "globalgame",
    timeline: "timeline",
    recyclinggame: "recyclegame",
    vgallery: "vgallery",
};
