import { useState, useCallback, useEffect, useContext } from "react";
import FeedbackAddModal from "./FeedbackAddModal";
import styles from "./index.module.scss";
import yellowLeafImage from "../../assets/images/leaf/yellowLeaf.png";
import greenLeafImage from "../../assets/images/leaf/greenLeaf.png";
import greenLeafImageUser from "../../assets/images/leaf/greenLeafUser.png";
import { getUserFeedbacks } from "../firebase";
import SideDrawer from "./SideDrawer";
import TutorialScreen from "./TutorialScreen";
import { UserContext } from "../../Contexts/UserContext/UserContextProvider";
import { CloseBtnSvg } from "../Svgs";

export const Leaf_Type = {
  YELLOW: "YELLOW",
  GREEN: "GREEN",
};

export const trimString = (string, limit) => {
  if (!string) {
    return string;
  }
  if (string.length <= limit) {
    return string;
  }
  return string.slice(0, limit) + "...";
};
export const getShuffledArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};
const leavesData = [
  {
    id: 1,
    type: Leaf_Type.YELLOW,
    className: `${styles.leaf}`,
    style: {
      margin: "-21% -22%",
      rotate: "-44deg",
      transform: "translate(-50%, -50%) scaleX(-1)",
    },
    message: "",
  },
  {
    id: 2,
    type: Leaf_Type.YELLOW,
    className: `${styles.leaf}`,
    style: {
      margin: "-19% -17%",
      rotate: "-1deg",
    },
    message: "",
  },
  {
    id: 3,
    type: Leaf_Type.YELLOW,
    className: `${styles.leaf}`,
    style: {
      margin: "-13% -17.5%",
      rotate: "-56deg",
      transform: "translate(-50%, -50%) scaleX(-1)",
    },
    message: "",
  },
  {
    id: 4,
    type: Leaf_Type.YELLOW,
    className: `${styles.leaf}`,
    style: {
      margin: "-13% -14%",
      rotate: "13deg",
    },
    message: "",
  },
  {
    id: 5,
    type: Leaf_Type.YELLOW,
    className: `${styles.leaf}`,
    style: {
      margin: "-5% -17%",
      rotate: "-52deg",
      transform: "translate(-50%, -50%) scaleX(-1)",
    },
    message: "",
  },
  {
    id: 6,
    type: Leaf_Type.YELLOW,
    className: `${styles.leaf}`,
    style: {
      margin: "-1% -12.3%",
      rotate: "18deg",
    },
    message: "",
  },
  {
    id: 7,
    type: Leaf_Type.YELLOW,
    className: `${styles.leaf}`,
    style: {
      margin: "-19% 15%",
      rotate: "-10deg",
      transform: "translate(-50%, -50%) scaleX(-1)",
    },
    message: "",
  },
  {
    id: 8,
    type: Leaf_Type.YELLOW,
    className: `${styles.leaf}`,
    style: {
      margin: "-14% 16.3%",
      rotate: "42deg",
    },
    message: "",
  },
  {
    id: 9,
    type: Leaf_Type.YELLOW,
    className: `${styles.leaf}`,
    style: {
      margin: "-13% 9%",
      rotate: "-14deg",
      transform: "translate(-50%, -50%) scaleX(-1)",
    },
    message: "",
  },
  {
    id: 10,
    type: Leaf_Type.YELLOW,
    className: `${styles.leaf}`,
    style: {
      margin: "-8% 8%",
      rotate: "71deg",
    },
    message: "",
  },
  {
    id: 11,
    type: Leaf_Type.YELLOW,
    className: `${styles.leaf}`,
    style: {
      margin: "-4% 1%",
      rotate: "-5deg",
      transform: "translate(-50%, -50%) scaleX(-1)",
    },
    message: "",
  },
  {
    id: 12,
    type: Leaf_Type.YELLOW,
    className: `${styles.leaf}`,
    style: {
      margin: "-1% 3%",
      rotate: "53deg",
    },
    message: "",
  },
  {
    id: 13,
    type: Leaf_Type.YELLOW,
    className: `${styles.leaf}`,
    style: {
      margin: "5% -8%",
      rotate: "-43deg",
      transform: "translate(-50%, -50%) scaleX(-1)",
    },
    message: "",
  },
  {
    id: 14,
    type: Leaf_Type.YELLOW,
    className: `${styles.leaf}`,
    style: {
      margin: "6% 0.3%",
      rotate: "57deg",
    },
    message: "",
  },
  {
    id: 15,
    type: Leaf_Type.YELLOW,
    className: `${styles.leaf}`,
    style: {
      margin: "5% -31%",
      rotate: "-72deg",
      transform: "translate(-50%, -50%) scaleX(-1)",
    },
    message: "",
  },
  {
    id: 16,
    type: Leaf_Type.YELLOW,
    className: `${styles.leaf}`,
    style: {
      margin: "3% -25.7%",
      rotate: "-54deg",
    },
    message: "",
  },
  {
    id: 17,
    type: Leaf_Type.YELLOW,
    className: `${styles.leaf}`,
    style: {
      margin: "8% -17%",
      rotate: "-89deg",
      transform: "translate(-50%, -50%) scaleX(-1)",
    },
    message: "",
  },
  {
    id: 18,
    type: Leaf_Type.YELLOW,
    className: `${styles.leaf}`,
    style: {
      margin: "3% -16.7%",
      rotate: "-65deg",
    },
    message: "",
  },
  {
    id: 19,
    type: Leaf_Type.YELLOW,
    className: `${styles.leaf}`,
    style: {
      margin: "2.5% 16%",
      rotate: "67deg",
      transform: "translate(-50%, -50%) scaleX(-1)",
    },
    message: "",
  },
  {
    id: 20,
    type: Leaf_Type.YELLOW,
    className: `${styles.leaf}`,
    style: {
      margin: "11% 12.7%",
      rotate: "67deg",
    },
    message: "",
  },
  {
    id: 21,
    type: Leaf_Type.YELLOW,
    className: `${styles.leaf}`,
    style: {
      margin: "16.5% 3%",
      rotate: "80deg",
    },
    message: "",
  },
];

const Leaf_Message_trim_length = 200;
const Leaf_Message_Blank_length = 5;
// var LeafIndex_For_Input = [];

// while (LeafIndex_For_Input.length < Leaf_Message_Blank_length) {
//   var r = Math.floor(Math.random() * leavesData.length);
//   if (LeafIndex_For_Input.indexOf(r) === -1) LeafIndex_For_Input.push(r);
// }

const initialModalData = {
  enable: false,
  data: null,
};
const FeedbackTree = ({ setShowTree }) => {
  const { user } = useContext(UserContext);
  const [modalData, setModalData] = useState(initialModalData);
  const [dataToDisplay, setDataToDisplay] = useState(
    JSON.parse(JSON.stringify(leavesData))
  );
  const [loading, setLoading] = useState(true);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const handleClose = useCallback(() => {
    setShowTree(false);
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      let res = await getUserFeedbacks({
        limit: leavesData.length - Leaf_Message_Blank_length,
      });
      if (res && res.length > 0) {
        setDataToDisplay((prev) => {
          let _prev = getShuffledArray([...prev]);
          for (let i = 0; i < _prev.length; i++) {
            if (res.length === i) {
              break;
            }

            if (_prev[i].type === Leaf_Type.YELLOW) {
              _prev[i].message = res[i].message;
              _prev[i].type = Leaf_Type.GREEN;
              _prev[i].name = res[i].name;
              _prev[i].email = res[i].email;
              _prev[i].timestamp = res[i].timestamp;
              _prev[i].uid = res[i].uid;
            }
          }

          setLoading(false);
          return _prev;
        });
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const openModal = useCallback((data) => {
    setModalData({
      enable: true,
      data: data,
    });
  }, []);

  const closeModal = useCallback((data) => {
    if (data && data.id) {
      let _m = trimString(data.message, Leaf_Message_trim_length);
      setDataToDisplay((prev) => {
        let _prev = JSON.parse(JSON.stringify(prev));
        _prev = _prev.map((item) => {
          if (item.id === data.id) {
            return {
              ...item,
              message: _m,
              type: Leaf_Type.GREEN,
              ...data,
            };
          }
          return item;
        });
        return _prev;
      });
    }
    setModalData(initialModalData);
  }, []);

  const openSideBar = useCallback(() => {
    setIsSideBarOpen(true);
  }, []);
  const closeSideBar = useCallback(() => {
    setIsSideBarOpen(false);
  }, []);

  return (
    <>
      <div className={`${styles.container}`}>
        {!isSideBarOpen && (
          <div onClick={handleClose} className={styles.closeBtn}>
            <CloseBtnSvg />
          </div>
        )}
        <div className={styles.imgContainer}>
          {/* bg start */}
          <img
            className={styles.bg}
            src={require("../../assets/images/leaf/bg.png")}
            alt=""
          />
          {/* bg end */}
          {/* leaves start*/}
          {!loading &&
            dataToDisplay.map((leaf, index) => {
              const { id, uid = "", type, message, className, style } = leaf;
              return (
                <div
                  key={index}
                  className={className}
                  style={style}
                  onClick={() => openModal(leaf)}
                >
                  <img
                    src={
                      type === Leaf_Type.GREEN
                        ? user && user.uid === uid
                          ? greenLeafImageUser
                          : greenLeafImage
                        : yellowLeafImage
                    }
                    alt=""
                  />
                  <div className={styles.message}>
                    {trimString(message, Leaf_Message_trim_length)}
                  </div>
                </div>
              );
            })}
          {/* leaves end*/}
        </div>
        {!isSideBarOpen && (
          <button
            onClick={openSideBar}
            className={`treeButton ${styles.archiveButton}`}
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.4719 28H27.7678M23.9438 6.96512L25.8558 8.87738M26.8118 2.18447C27.1885 2.56111 27.4873 3.00828 27.6912 3.50045C27.8951 3.99261 28 4.52013 28 5.05286C28 5.58559 27.8951 6.11311 27.6912 6.60527C27.4873 7.09744 27.1885 7.54461 26.8118 7.92125L8.64794 26.0877L1 28L2.91198 20.458L21.0835 2.19212C21.7997 1.47229 22.7604 1.04813 23.7748 1.00385C24.7892 0.959571 25.7832 1.2984 26.5594 1.95308L26.8118 2.18447Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Archive
          </button>
        )}
      </div>
      <SideDrawer isOpen={isSideBarOpen} closeSideBar={closeSideBar} />
      <FeedbackAddModal modalData={modalData} closeModal={closeModal} />
      {sessionStorage.getItem("signatureWallTut") == "true" ? null : (
        <TutorialScreen />
      )}
    </>
  );
};

export default FeedbackTree;
