export const CloseBtnSvg = ({ color = "#fff" }) => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.17647 0.541009L10.6521 8.27543L18.0889 0.581083C18.2532 0.400181 18.4511 0.255466 18.6707 0.155615C18.8904 0.0557636 19.1272 0.00283397 19.3671 0C19.8808 0 20.3734 0.211107 20.7366 0.586881C21.0998 0.962654 21.3038 1.47231 21.3038 2.00374C21.3083 2.2494 21.2643 2.49342 21.1744 2.72084C21.0844 2.94825 20.9506 3.15425 20.7809 3.3262L13.2472 11.0205L20.7809 18.8151C21.1001 19.1382 21.2873 19.5757 21.3038 20.0374C21.3038 20.5688 21.0998 21.0784 20.7366 21.4542C20.3734 21.83 19.8808 22.0411 19.3671 22.0411C19.1203 22.0517 18.8741 22.0091 18.644 21.9159C18.414 21.8228 18.2052 21.6812 18.0308 21.5001L10.6521 13.7657L3.19584 21.4801C3.0322 21.6549 2.83671 21.7945 2.62065 21.8908C2.40459 21.9871 2.17224 22.0382 1.937 22.0411C1.42336 22.0411 0.930755 21.83 0.567556 21.4542C0.204358 21.0784 0.000315111 20.5688 0.000315111 20.0374C-0.00420025 19.7917 0.0398533 19.5477 0.129773 19.3203C0.219692 19.0928 0.35358 18.8868 0.523219 18.7149L8.05691 11.0205L0.523219 3.22601C0.204025 2.90293 0.0168536 2.46542 0.000315111 2.00374C0.000315111 1.47231 0.204358 0.962654 0.567556 0.586881C0.930755 0.211107 1.42336 0 1.937 0C2.4018 0.00601121 2.84724 0.200374 3.17647 0.541009Z"
        fill={color}
      />
    </svg>
  );
};
