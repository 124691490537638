import parrotModal from "../../assets/3dModal/parrot.glb"
import ganeshaModal from "../../assets/3dModal/ganesha.glb"
import yakashiModal from "../../assets/3dModal/yakashi.glb"
import pratyanghiraModal from "../../assets/3dModal/pratyanghira.glb"
import budhha from "../../assets/3dModal/buddha.glb"
import varahaModel from "../../assets/3dModal/varaha.glb"
import laxmiModel from "../../assets/3dModal/lakhsminarayan.glb"
import terraModel from "../../assets/3dModal/terracotta.glb"
import suryaModel from "../../assets/3dModal/surya.glb"
import nandi from "../../assets/3dModal/nandikesha.glb"
import jinasModel from "../../assets/3dModal/jinas.glb"
import sridevi from "../../assets/3dModal/sridevi.glb"
import mahishasundramModel from "../../assets/3dModal/mahisashurmardhini.glb"
import satedJinasModel from "../../assets/3dModal/satedJina.glb"
import manjuShreeModal from "../../assets/3dModal/manjushree.glb"
import sambandarModel from "../../assets/3dModal/dancingSambandar.glb"
import vishnuBattleModal from "../../assets/3dModal/vishnuInBattle.glb"
import drumModel from "../../assets/3dModal/ladyPlayingdrum.glb"
import brahmaModel from "../../assets/3dModal/brahma.glb"
import aminModel from "../../assets/3dModal/amin.glb"
import anthoModel from "../../assets/3dModal/anthromorphic.glb"
import budhhStanding from "../../assets/3dModal/buddhStanding.glb"
import umaModel from "../../assets/3dModal/uma.glb"
import swordModel from "../../assets/3dModal/sword.glb"




export const Relic3dModals = {
    parrot: {
        model: parrotModal,
        objectLabel: true,
        relicText: {
            heading: "Parrot Lady ",
            subHeading: "3446",
            origin: "10th-11th Century C.E, \n Khajuraho, Madhya Pradesh",
            material: "Stone",
            country: "Repatriated from Canada in 2015"
        }
    },
    ganesha: {
        model: ganeshaModal,
        objectLabel: true,
        relicText: {
            heading: "Dancing Ganesha",
            subHeading: "RET 124",
            origin: "12th Century C.E. Central India",
            material: "Stone",
            country: "Repatriated from USA in 2021"
        }
    },
    bram: {
        model: brahmaModel,
        objectLabel: true,
        relicText: {
            heading: "Stone sculpture of Brahma and Brahamani",
            subHeading: "CAC/RET-32",
            origin: "11th-12th Century C.E. \n Gujarat",
            material: "Marble",
            country: "Repatriated from UK in 2017"
        }
    },
    amin: {
        model: aminModel,
        relicText: {
            heading: "Yaksha, Amin Pillar",
            subHeading: "Acc No- 16/M/1",
            origin: "Sunga, 2nd Century B.C.E. \n Amin, Haryana",
            material: "Stone",
            country: "Repatriated from UK in 1979-80"
        }
    },
    // buddha: {
    //     model: budhha,
    //     relicText: {
    //         heading: "Painting depicting ",
    //         subHeading: "",
    //         origin: "Rasikapriya from the SamdehiRagini \n Bikaner, Rajasthan",
    //         material: "Paper",
    //         country: "Repatriated from USA in 2021"
    //     }
    // },
    specs: {
        model: pratyanghiraModal,
        objectLabel: true,
        brightnessNeeded: true,
        relicText: {
            heading: "Standing Pratyangira \n (Replica)",
            subHeading: "",
            origin: "Chola, 13th Century C.E. \n Tamil Nadu",
            material: "Grey coloured Granite Stone",
            country: "Repatriated from Australia in 2016"
        }
    },
    yakashi: {
        model: yakashiModal,
        objectLabel: true,
        relicText: {
            heading: "Terracotta Yakashi of Tamluk",
            subHeading: "CAC/RET/1",
            origin: "West Bengal",
            material: "Terracotta",
            country: "Repatriated from UK in 1986"
        }
    },
    terra: {
        model: terraModel,
        objectLabel: true,
        relicText: {
            heading: "Terracotta figure from Bhitargaon",
            subHeading: "CAC/RET/2",
            origin: "Uttar Pradesh",
            material: "Terracotta",
            country: "Repatriated from USA in 1991"
        }
    },
    buddha: {
        model: budhha,
        objectLabel: true,
        brightnessNeeded: true,
        relicText: {
            heading: "Image of Buddha",
            subHeading: "CAC/RET?34",
            origin: "Bihar",
            material: "Metal",
            country: "Repatriated from UK in 2019"
        }
    },
    manju: {
        model: manjuShreeModal,
        objectLabel: true,
        relicText: {
            heading: "Manjushri",
            subHeading: "",
            origin: "Eastern India",
            material: "Stone",
            country: "Repatriated from USA in 2021"
        }
    },
    varaha: {
        model: varahaModel,
        objectLabel: true,
        relicText: {
            heading: "Stele of Varaha",
            subHeading: "",
            origin: "Eastern India",
            material: "Stone",
            country: "Repatriated from USA in 2021"
        }
    },
    surya: {
        model: suryaModel,
        objectLabel: true,
        relicText: {
            heading: "Surya",
            subHeading: "CAC?RET/55",
            origin: "Central India",
            material: "Stone",
            country: "Repatriated from USA in 2021"
        }
    },

    nandi: {
        model: nandi,
        objectLabel: true,
        brightnessNeeded: true,
        relicText: {
            heading: "Nandikesa",
            subHeading: "RET/60",
            origin: "Chola, 12th Century C.E. \n Tamil Nadu",
            material: "Metal",
            country: "Repatriated from USA in 2021"
        }
    },

    battle: {
        model: vishnuBattleModal,
        objectLabel: true,
        relicText: {
            heading: "Panel depicting Vishnu in a battle scene",
            subHeading: "RET/66",
            origin: "12th Century C.E. \n Central India",
            material: "Stone",
            country: "Repatriated from USA in 2021"
        }
    },
    uma: {
        model: umaModel,
        objectLabel: true,
        relicText: {
            heading: "Uma Maheshwar",
            subHeading: "CAC/RET/90",
            origin: "11th Century C.E. Central India",
            material: "Stone",
            country: "Repatriated from USA in 2021"
        }
    },
    anth: {
        model: anthoModel,
        objectLabel: true,
        relicText: {
            heading: "Anthropomorphic figure",
            subHeading: "CAC/RET/99",
            origin: "2000-1800 B.C.E. \n North India",
            material: "Copper",
            country: "Repatriated from USA in 2021"
        }
    },
    sword: {
        model: swordModel,
        objectLabel: true,
        relicText: {
            heading: "Sword with sheath",
            subHeading: "RET/100",
            origin: "Early half of the 17th Century C.E. \n Punjab",
            material: "Iron sword",
            country: "Repatriated from USA in 2021"
        }
    },
    laxmi: {
        model: laxmiModel,
        objectLabel: true,
        brightnessNeeded: true,
        relicText: {
            heading: "Lakshmi Narayana",
            subHeading: "CAC/RET/102",
            origin: "15th-16th Century C.E. \n Eastern India",
            material: "Metal",
            country: "Repatriated from USA in 2021"
        }
    },
    drum: {
        model: drumModel,
        objectLabel: true,
        relicText: {
            heading: "Female playing drum",
            subHeading: "CAC/RET/104",
            origin: "13th-14th century C.E. \n Central India",
            material: "Stone",
            country: "Repatriated from USA in 2021"
        }
    },
    jainas: {
        model: jinasModel,
        objectLabel: true,
        brightnessNeeded: true,
        relicText: {
            heading: "Jain shrine with twenty- four Jainas",
            subHeading: "CAC/RET/45",
            origin: "Western India",
            material: "Metal",
            country: "Repatriated from USA in 2021"
        }
    },
    sam: {
        model: sambandarModel,
        objectLabel: true,
        relicText: {
            heading: "Dancing Sambandar",
            subHeading: "RET/HQ/1",
            origin: "Tamil Nadu",
            material: "Metal",
            country: "Repatriated from Australia in 2022"
        }
    },
    buddh: {
        model: budhhStanding,
        objectLabel: true,
        brightnessNeeded: false,
        relicText: {
            heading: "Buddha",
            subHeading: "CAC/RET/61",
            origin: "14th-15th Century C.E. \n Eastern India",
            material: "Metal",
            country: "Repatriated from USA in 2021"
        }
    },
    Letter: {
        model: jinasModel,
        relicText: {
            heading: "Letter of invitation to Jain monks, \n  picture scroll, (Vijnaptipatra)",
            subHeading: "CAC/RET/61",
            origin: "Rajasthan",
            material: "Paper",
            country: "Repatriated from Australia in 2022"
        }
    },
    sated: {
        model: satedJinasModel,
        relicText: {
            heading: "Sated Jina",
            subHeading: "2003.478",
            origin: "12th century CE",
            material: "Marble",
            country: "55x44x21 cm"
        }
    },
    mahi: {
        model: mahishasundramModel,
        relicText: {
            heading: "Mahishasurmardini",
            subHeading: "CAC/RET/33",
            origin: "10th-11th Century C.E. \n Almora, Uttarakhand",
            material: "Stone",
            country: "Repatriated from Metropolitan Museum \n from of Art, New York, USA in 2018"
        }
    },
    sridevi: {
        model: sridevi,
        relicText: {
            heading: "Sridevi",
            subHeading: "CAC/RET/17",
            origin: "Chola, 12th-13th Century C.E. \n Tamil Nadu",
            material: "Metal",
            country: "Repatriated from USA in 2016"
        }
    },
}