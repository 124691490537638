import { forwardRef, useRef, useEffect } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { useGLTF, OrbitControls } from "@react-three/drei";
import styles from "./index.module.scss";
import { MeshLambertMaterial, PointLight, Vector2, Vector3 } from "three";
import loader from "../../assets/gif/loader.gif"
import { Suspense } from "react";
import { Bloom, BrightnessContrast, Selection, EffectComposer, Select } from '@react-three/postprocessing'
import LoaderUI from "../LoaderUI";
import { useState } from "react";

// import studio from "@theatre/studio";
// import extension from "@theatre/r3f/dist/extension";
// import { SheetProvider, editable as e, PerspectiveCamera } from "@theatre/r3f";
// import { getProject } from "@theatre/core";

// our Theatre.js project sheet, we'll use this later
// const sheet = getProject("Modal Project").sheet("Demo Sheet");

export default function Modal360(props) {
  const {
    cameraFOV = 75,
    cameraPOSITION = 0,
    modal,
    modalPosition = 0,
    modalRotation = 0,
    cameraOffset = 20
  } = props;
  const modalRef = useRef();
  const cameraRef = useRef()
  const [loaderUi, setLoaderUi] = useState(true)
  // useEffect(() => {
  //   if (process.env.NODE_ENV === "development") {
  //     studio.initialize();
  //     studio.extend(extension);
  //   }
  // }, [])

  useEffect(() => {
    return () => {
      setLoaderUi(false)
    }
  }, [])


  return (
    <div className={`${styles.modal360} animate__animated animate__fadeIn `}>
      {loaderUi && <LoaderUI />}
      <Canvas
        shadows
        dpr={[1, 2]}
        camera={{
          position: [cameraPOSITION.x, cameraPOSITION.y, cameraPOSITION.z],
          fov: cameraFOV,
          rotation: [-3.141592653589793, 1.5071561607240065, -3.141592653589793]
        }}
      >

        <ambientLight color={"0xfff"} />

        {/* <Selection>
          <Select enabled> */}
        <Modal
          cameraPOSITION={new Vector3(cameraPOSITION.x, cameraPOSITION.y, cameraPOSITION.z)}
          modal={modal}
          modalPosition={modalPosition}
          modalRotation={modalRotation}
          ref={modalRef}
          cameraRef={cameraRef}
        />
        {/* </Select> */}

        <EffectComposer stencilBuffer={false} autoclear={true}>
          {
            props.brightnessNeeded &&
            <BrightnessContrast
              brightness={0.25} // brightness. min: -1, max: 1
              contrast={0.45} // contrast: min -1, max: 1
            />
          }
        </EffectComposer>

        {/* <SheetProvider sheet={sheet}>
          <PerspectiveCamera
            theatreKey="Camera"
            makeDefault
            position={[cameraPOSITION.x, cameraPOSITION.y, cameraPOSITION.z]}
            fov={cameraFOV}
          /> */}


        {/* </SheetProvider> */}
        {/* </Selection> */}
      </Canvas >

    </div >
  );
}

const Modal = forwardRef(
  ({ modal, modalPosition, modalRotation, modalRef, cameraPOSITION, cameraRef, ...props }, ref) => {
    const { scene } = useGLTF(modal);
    const m = scene.getObjectByName(scene.children[0].name)


    // useEffect(() => {
    //   cameraRef.current = camera
    //   window.resetCameraPos = resetCameraPos
    // }, [])

    const orbRef = useRef(null);


    useFrame(() => {


      if (orbRef.current) {

        let currentAngle = orbRef.current.getAzimuthalAngle() * 90 / Math.PI
        if (currentAngle >= 89) {
          orbRef.current.autoRotateSpeed = 2;
        } else if (currentAngle == 0) {
          orbRef.current.autoRotateSpeed = -2;
        }
      }

    })

    const timerRef = useRef(null)
    const stimerRef = useRef(null)
    useEffect(() => {
      const handleBtnDown = (e) => {
        if (timerRef.current) {
          clearTimeout(timerRef.current)
        }
        if (orbRef.current)
          orbRef.current.autoRotate = false;
      }
      const handleBtnUp = (e) => {
        timerRef.current = setTimeout(() => {
          if (orbRef.current)
            orbRef.current.autoRotate = true;
        }, 4000)
      }
      if (orbRef.current) {
        if (stimerRef.current) {
          clearTimeout(stimerRef.current)
        }
        stimerRef.current = setTimeout(() => {
          if (orbRef.current) {
            orbRef.current.minDistance = 5
            orbRef.current.autoRotate = true
          }
        }, 2000)
        window.addEventListener('touchstart', handleBtnDown)
        window.addEventListener('mousedown', handleBtnDown)
        window.addEventListener('pointerdown', handleBtnDown)

        window.addEventListener('touchend', handleBtnUp)
        window.addEventListener('mouseup', handleBtnUp)
        window.addEventListener('pointerup', handleBtnUp)
      }

      return () => {
        window.removeEventListener('touchstart', handleBtnDown)
        window.removeEventListener('mousedown', handleBtnDown)
        window.removeEventListener('pointerdown', handleBtnDown)

        window.removeEventListener('touchend', handleBtnUp)
        window.removeEventListener('mouseup', handleBtnUp)
        window.removeEventListener('pointerup', handleBtnUp)
      }
    }, [orbRef.current])

    const [sjow, setsjow] = useState(false)
    useEffect(() => {
      setTimeout(() => {
        setsjow(true)
      }, 5)
    }, [])

    return (
      <>
        <OrbitControls

          ref={orbRef}
          autoRotateSpeed={2}
          autoRotate={false}
          minPolarAngle={0}
          maxPolarAngle={Math.PI}
          minAzimuthAngle={0}
          maxAzimuthAngle={Math.PI}
          // screenSpacePanning={false}
          // enableZoom={true}
          minDistance={20}
          maxDistance={40}
        // enablePan={true}
        />
        {
          sjow &&
          <mesh
            dispose
            // theatreKey="Modal"
            // material={m.material}
            // geometry={m.geometry}
            ref={ref}
            {...props}
            scale={2}
            position={[modalPosition.x, modalPosition.y, modalPosition.z]}
            rotation={[modalRotation.x, modalRotation.y, modalRotation.z]}
          >
            <primitive object={scene} dispose={null} />
          </mesh>

        }
        {/* <primitive object={scene} dispose={null} /> */}
      </>
    );
  }
);
