import { useProgress } from "@react-three/drei";
import styles from "./index.module.scss";
import { useEffect, useRef, useState } from "react";

const LoaderUI = () => {
  const { active, progress, errors, item, loaded, total } = useProgress();
  const loaderRef = useRef();

  document?.documentElement?.style.setProperty("--progress", `${progress}%`);

  if (loaderRef.current) {
    loaderRef.current.innerHTML = `${parseInt(progress)}%`;
    if (progress < 100) {
      loaderRef.current.style.display = "grid"
    }
    if (progress >= 100) {
      loaderRef.current.style.display = "none"
    }
  }
  // useEffect(() => {
  //   if (loaderRef.current && progress == 100) {
  //     loaderRef.current.style.display = "none"
  //   }
  // }, [loaderRef, progress])
  // console.log(progress, 'progressss')

  return (
    <div
      ref={loaderRef}
      className={styles["RadialProgress"]}
      role="progressbar"
      aria-valuenow={progress}
      aria-valuemin="0"
      aria-valuemax="100"
    ></div>
  )
};

export default LoaderUI;
