import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { updateCountDownData } from "../firebase";

const Countdown = ({ data }) => {
  //   const EventTime = "Jul 21, 2024 12:00:00";
  const countDownDate = new Date(data.time).getTime();

  const [timeLeft, setTimeLeft] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  useEffect(() => {
    const countDown = () => {
      const now = new Date().getTime();
      const timeleft = countDownDate - now;

      if (timeleft < 0) {
        // hide timer 
        updateCountDownData({
          show: false
        })
        clearInterval(interval);
        setTimeLeft({
          days: "00",
          hours: "00",
          minutes: "00",
          seconds: "00",
        });
      } else {
        const days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

        setTimeLeft({
          days: (days < 10 ? "0" : "") + days,
          hours: (hours < 10 ? "0" : "") + hours,
          minutes: (minutes < 10 ? "0" : "") + minutes,
          seconds: (seconds < 10 ? "0" : "") + seconds,
        });
      }
    };

    const interval = setInterval(countDown, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return (
    <div className={styles.container}>
      <div className={styles.heading}>Event shall be live soon</div>

      <div className={styles["countdown-container"]}>
        <div id="days">{timeLeft.days}&nbsp;:&nbsp;</div>
        <div id="hours">{timeLeft.hours}&nbsp;:&nbsp;</div>
        <div id="mins">{timeLeft.minutes}&nbsp;:&nbsp;</div>
        <div id="secs">{timeLeft.seconds}</div>
        {/* {timeLeft.days === "00" &&
            timeLeft.hours === "00" &&
            timeLeft.minutes === "00" &&
            timeLeft.seconds === "00" && (
              <div id="end">
                00&nbsp;:&nbsp;00&nbsp;:&nbsp;00&nbsp;:&nbsp;00
              </div>
            )} */}
      </div>
    </div>
  );
};

export default Countdown;
