import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./styles/global.css"
import AppWrapper from './components/AppWrapper';
import UserContextProvider from './Contexts/UserContext/UserContextProvider';
import LocationContextProvider from './Contexts/LocationProvider';
// import LandingPage from './scenes/LandingPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <UserContextProvider>
      {/* <LandingPage/> */}
      <LocationContextProvider>
        <AppWrapper />
      </LocationContextProvider>
    </UserContextProvider>
  </>
);

