import { createContext } from "react";
import Controls from "../../components/Controls";
import { useReducer } from "react";

const INITIAL_STATE = {
    currentLocation: -1
}

const ACTION_TYPES = {
    handleUpdateLocation: "handleUpdateLocation"
}

const reducer = (state, action) => {
    const { type, payload } = action
    switch (type) {
        case ACTION_TYPES.handleUpdateLocation:
            return { ...state, ...payload }

        default:
            throw new Error();
    }
}


export const LocationContext = createContext({ showControls: false });

function LocationContextProvider(props) {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

    const updateLocation = (value) => {
        dispatch({
            type: ACTION_TYPES.handleUpdateLocation,
            payload: { currentLocation: value }
        })
    }

    return (
        <LocationContext.Provider
            value={{
                updateLocation,
                currentLocation: state.currentLocation
            }}
        >
            {props.children}

        </LocationContext.Provider>
    );
}
export default LocationContextProvider;
