import React, { useContext, useReducer, useRef } from 'react'
import styles from "./index.module.scss"
import CustomButton from '../../components/CustomButton'
import { useNavigate } from 'react-router-dom'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { getCountryCallingCode } from 'react-phone-number-input/input'
import { countries } from './countries'
import { useState } from 'react'
import { addUserDetails, signUpWithId } from '../../components/firebase'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify'
import { UserContext } from '../../Contexts/UserContext/UserContextProvider'
// import dropdown from "../../assets/images/dropdown.png"
// import { isMobile } from 'react-device-detect'
// import FullScreenVideoController from '../../components/FullScreenVideoController'
// import promoVideo from "../../assets/videos/logos.mp4"
import Countdown from '../../components/Countdown'




const inputs = [
    {
        name: "name",
        label: "Name*",
        type: "text"
    },
    {
        name: "email",
        label: "Email*",
        type: "text"
    },
    {
        name: "phone",
        label: "Phone No.",
        type: "phone"
    },
    {
        name: "country",
        label: "Country*",
        type: "dropdown",
        options: countries
    },
    {
        name: "profession",
        label: "Profession",
        type: "dropdown",
        options: [
            {
                name: "Public"
            },
            {
                name: "Private"
            },
            {
                name: "Student"
            },
        ]
    },
]

const defaultErr = {
    name: "",
    email: "",
    phone: "",
    country: "",
    profession: "",
}


const initialStates = {
    name: "",
    email: "",
    phone: "",
    country: "",
    profession: "",
    err: { ...defaultErr }
};

const ACTION_TYPES = {
    handleInputChange: "handleInputChange",
    handleError: "handleError",
    loading: "loading"
};

const reducer = (state, action) => {
    let { type, payload } = action;
    switch (type) {
        case ACTION_TYPES.handleInputChange:
            return {
                ...state,
                ...payload,
            };
        case ACTION_TYPES.handleError:
            return {
                ...state,
                ...payload,
            };
        case ACTION_TYPES.loading:
            return {
                ...state,
                ...payload,
            };
        default:
            return { ...state };
    }
};



const Register = () => {
    const [state, dispatch] = useReducer(reducer, initialStates);
    const isTriedSubmitting = useRef(false)
    const [loading, setLoading] = useState(false)
    const timerRef = useRef(null)

    const [country, selectedCountry] = useState("IN")
    const { setUserDetails, setUser, countdownData } = useContext(UserContext)

    const validateEmail = (email) => {
        const re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
    const validateForm = () => {

        let errors = state.err;

        errors.name =
            state.name && state.name.trim().length > 0 ? "" : "Please enter name.";
        errors.email = state.email && state.email.trim().length > 0 && validateEmail(state.email) ? "" : "Please enter email.";
        // errors.phone =
        //     state.phone && state.phone.trim().length > 0 && state.phone.trim().length == 13
        //         ? ""
        //         : "Please enter valid Phone No.";
        errors.country =
            state.country && state.country.trim().length > 0
                ? ""
                : "Please select Country.";
        // errors.profession =
        //     state.profession && state.profession.trim().length > 0 ? "" : "Please enter Profession.";

        dispatch({
            type: ACTION_TYPES.handleError,
            payload: { err: { ...errors } },
        });
    };

    const isValidForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
        return valid;
    };

    const navigation = useNavigate()

    const handleInputChange = (e) => {
        if (isTriedSubmitting.current) {
            validateForm();

        }
        dispatch({
            type: ACTION_TYPES.handleInputChange,
            payload: { [e.target.name]: e.target.value }
        })
    }

    const handlePhone = (val) => {
        dispatch({
            type: ACTION_TYPES.handleInputChange,
            payload: { phone: val }
        })
    }

    const handleBack = () => {
        navigation(-1)
    }

    const handleLoginPage = () => {
        navigation("/login")
    }

    const handleRegister = () => {
        setLoading(true)
        isTriedSubmitting.current = true
        validateForm();
        if (!isValidForm(state.err)) {
            dispatch({
                type: ACTION_TYPES.loading,
                payload: { loading: false },
            });
            setLoading(false)

            return;
        }
        let { name, email, phone, country, profession } = state
        signUpWithId(name, email).then((res) => {
            if (res) {
                let user = {
                    name: name,
                    email: email,
                    uid: res.uid,
                }
                setUser(user)
                let userDetals = { id: res.uid, name, email, phone, country: country && country.length > 0 ? country : null, profession: profession && profession.length > 0 ? profession : null }
                addUserDetails(res.uid, userDetals)
                localStorage.setItem("user", JSON.stringify(user));
                localStorage.setItem("userDetails", JSON.stringify(userDetals));
                setUserDetails(res);
                // localStorage.setItem("userDetails", JSON.stringify({ id: res.uid, name, email, phone, country, profession }))
                // setUserDetails({ id: res.uid, name, email, phone, country, profession })
                if (timerRef.current) {
                    clearTimeout(timerRef.current)
                }
                timerRef.current = setTimeout(() => {
                    navigation("/welcome")
                }, 200)
                setLoading(false)
            } else {
                setLoading(false)

                toast.error('Network Error', {
                    position: "bottom-right",
                    autoClose: 5000,
                    zIndex: 1000,
                    hideProgressBar: false,
                    error: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        }
        ).catch(err => {
            setLoading(false)

            if (err.code == "auth/email-already-in-use") {
                toast.error('Email Already Exists', {
                    position: "bottom-right",
                    autoClose: 5000,
                    zIndex: 1000,
                    hideProgressBar: false,
                    error: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        })

    }

    return (
        <div className={`${styles.registerWrapper}`}>
            {/* {!isMobile && <div className={styles.sideVideo}>
                <FullScreenVideoController logos={true} url={promoVideo} />
            </div>} */}
            {countdownData.checking ?
                <div className={"mainLoader"}></div>
                : countdownData.show ?
                    <div className={styles.registerContainer}>
                        <Countdown data={countdownData} /></div> :
            <div className={styles.registerContainer}>
                <div onClick={handleBack} className={`${styles.backBtn} animate__animated animate__fadeIn`}><svg viewBox="0 0" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.2969 23.4375L5.85938 15L14.2969 6.5625M7.03125 15H24.1406" stroke="white" strokeWidth="2.1875" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                </div>
                <div className={styles.registerContent}>
                    <div className={`${styles.title} animate__animated animate__fadeIn`}>Register <br />
                        Yourself</div>
                    <div className={styles.inputWrapper}>
                        {inputs.map((input, index) =>
                            input.type === "text" ?
                                <div key={index} className={`${styles.inputBox} animate__animated animate__fadeIn`}>
                                    <input name={input.name} onChange={handleInputChange} value={state[input.name]} type={input.type} placeholder={input.label} className={`${styles.input} animate__animated animate__fadeIn`} />
                                    {state.err[input.name].length > 0 && <div className={styles.error}>{state.err[input.name]}</div>}
                                </div> :
                                input.type === "phone" ?
                                    <div key={index} className={`${styles.phoneInputBox} animate__animated animate__fadeIn`}>
                                        <div className={styles.countryCode}>
                                            {`+ ${getCountryCallingCode(country)} `}
                                            {/* <div className={styles.dropdown}>
                                                <img src={dropdown} alt="dropdown" />
                                            </div> */}
                                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" id="phone"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M19.23 15.26l-2.54-.29c-.61-.07-1.21.14-1.64.57l-1.84 1.84c-2.83-1.44-5.15-3.75-6.59-6.59l1.85-1.85c.43-.43.64-1.03.57-1.64l-.29-2.52c-.12-1.01-.97-1.77-1.99-1.77H5.03c-1.13 0-2.07.94-2 2.07.53 8.54 7.36 15.36 15.89 15.89 1.13.07 2.07-.87 2.07-2v-1.73c.01-1.01-.75-1.86-1.76-1.98z"></path></svg> */}
                                        </div>
                                        <PhoneInput
                                            defaultCountry="IN"
                                            className='animate__animated animate__fadeIn'
                                            name={input.name}
                                            placeholder="Enter phone number"
                                            international={false}
                                            value={state[input.name]}
                                            onCountryChange={(e) => selectedCountry(e)}
                                            onChange={handlePhone} />
                                        {state.err[input.name].length > 0 && <div className={styles.error}>{state.err[input.name]}</div>}
                                    </div>
                                    : <div key={index} className={styles.selectBoxWrappers}>
                                        <div className={`${styles.inputSelectBox} animate__animated animate__fadeIn`}>
                                            <select placeholder='country' name={input.name} onChange={handleInputChange} className={styles.input}>
                                                <option style={{ display: "none" }} value="" selected disabled hidden>{input.label}</option>
                                                {input.options.map(opt =>
                                                    <option selected={state[input.name] == opt.label}>{opt.name}</option>
                                                )}
                                            </select>
                                            {/* <Select dropdownRenderer={(val) => {
                                        const { props } = val
                                        return (
                                            <ul className="selectUl">
                                                {props.options.map(option =>
                                                    <li className='selectOption'>{option.label}</li>
                                                )}
                                            </ul>
                                        )

                                    }} placeholder={input.label} style={{ zIndex: 100 }} className={`${ styles.input } animate__animated animate__fadeIn`} options={input.options} onChange={handleSelect} />  */}
                                        </div>
                                        {state.err[input.name].length > 0 && <div className={styles.error}>{state.err[input.name]}</div>}</div>

                        )}
                    </div>
                    <ToastContainer />
                    <CustomButton isLoading={loading} onClick={handleRegister}>Register</CustomButton>
                </div>
                <div className={`${styles.bottomText} animate__animated animate__fadeIn`}>Already Registered ? <span onClick={handleLoginPage}>Log In</span></div>
            </div>}
        </div >

    )
}

export default Register