import React, { forwardRef, useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import spaceImage from "../../assets/images/map.png";
import { zonesData, zonesDataMob } from "./data";
// import Header, { iOS } from "../Header";
// import ZoneList from "./SubComp/ZoneList";
// import ZoneListMob from "./SubComp/ZoneListMob";
import CustomButton from "../CustomButton";
import { iOS } from "../AppWrapper";
import ZoneList from "./SubComp/ZoneList";
import { useNavigate } from "react-router-dom";
import { ROUTES_CONSTANT } from "../../constants/RoutesContstants";
import { panoromaId } from "../../constants/PanoramaConstants";

const fadeOutFrame = (nodeREf, cb = () => { }, removeAnimationClass = false) => {
    let animationName = "animate__zoomOut";
    let prefix = "animate__";
    nodeREf.classList.add(
        `${prefix}animated`,
        animationName,
        "animation__forwards"
    );
    function handleAnimationEnd(event) {
        event.stopPropagation();
        if (removeAnimationClass)
            nodeREf.classList.remove(`${prefix}animated`, animationName);
        cb();
    }
    nodeREf.addEventListener("animationend", handleAnimationEnd, { once: true });
};

const getAnimationSpeedInList = (index = 0, length = 1) => {
    let percentage = index / length;
    if (percentage <= 0.25) {
        return "animate__faster";
    } else if (percentage <= 0.75) {
        return "animate__fast";
    } else if (percentage <= 0.75) {
        return "animate__slow";
    } else {
        return ""; //'animate__slower'
    }
};

const Marker = forwardRef((props, ref) => {
    return (
        <svg
            ref={ref}
            className={`animate__animated animate__bounceIn`}
            viewBox="0 0 59 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M29.2465 35.9039C30.9822 35.9039 32.4686 35.2786 33.7057 34.0279C34.9407 32.7794 35.5581 31.2778 35.5581 29.5231C35.5581 27.7684 34.9407 26.2657 33.7057 25.0151C32.4686 23.7666 30.9822 23.1423 29.2465 23.1423C27.5108 23.1423 26.0255 23.7666 24.7905 25.0151C23.5534 26.2657 22.9349 27.7684 22.9349 29.5231C22.9349 31.2778 23.5534 32.7794 24.7905 34.0279C26.0255 35.2786 27.5108 35.9039 29.2465 35.9039ZM29.2465 59.3532C35.6633 53.3978 40.4234 47.9869 43.5266 43.1205C46.6298 38.2563 48.1814 33.9365 48.1814 30.1612C48.1814 24.3653 46.3531 19.6191 42.6966 15.9225C39.0422 12.228 34.5588 10.3808 29.2465 10.3808C23.9342 10.3808 19.4498 12.228 15.7933 15.9225C12.1388 19.6191 10.3116 24.3653 10.3116 30.1612C10.3116 33.9365 11.8632 38.2563 14.9665 43.1205C18.0697 47.9869 22.8297 53.3978 29.2465 59.3532ZM29.2465 66.6114C28.8257 66.6114 28.405 66.5316 27.9842 66.3721C27.5634 66.2126 27.1952 65.9999 26.8797 65.734C19.2005 58.8747 13.4674 52.5077 9.68047 46.6332C5.89349 40.7565 4 35.2658 4 30.1612C4 22.1852 6.53833 15.831 11.615 11.0986C16.6895 6.3662 22.5667 4 29.2465 4C35.9263 4 41.8035 6.3662 46.8781 11.0986C51.9547 15.831 54.493 22.1852 54.493 30.1612C54.493 35.2658 52.5996 40.7565 48.8126 46.6332C45.0256 52.5077 39.2925 58.8747 31.6134 65.734C31.2978 65.9999 30.9296 66.2126 30.5088 66.3721C30.0881 66.5316 29.6673 66.6114 29.2465 66.6114Z"
                fill="white"
            />
            <g filter="url(#filter0_f_1_1614)">
                <path
                    d="M29.2465 35.9039C30.9822 35.9039 32.4686 35.2786 33.7057 34.0279C34.9407 32.7794 35.5581 31.2778 35.5581 29.5231C35.5581 27.7684 34.9407 26.2657 33.7057 25.0151C32.4686 23.7666 30.9822 23.1423 29.2465 23.1423C27.5108 23.1423 26.0255 23.7666 24.7905 25.0151C23.5534 26.2657 22.9349 27.7684 22.9349 29.5231C22.9349 31.2778 23.5534 32.7794 24.7905 34.0279C26.0255 35.2786 27.5108 35.9039 29.2465 35.9039ZM29.2465 59.3532C35.6633 53.3978 40.4234 47.9869 43.5266 43.1205C46.6298 38.2563 48.1814 33.9365 48.1814 30.1612C48.1814 24.3653 46.3531 19.6191 42.6966 15.9225C39.0422 12.228 34.5588 10.3808 29.2465 10.3808C23.9342 10.3808 19.4498 12.228 15.7933 15.9225C12.1388 19.6191 10.3116 24.3653 10.3116 30.1612C10.3116 33.9365 11.8632 38.2563 14.9665 43.1205C18.0697 47.9869 22.8297 53.3978 29.2465 59.3532ZM29.2465 66.6114C28.8257 66.6114 28.405 66.5316 27.9842 66.3721C27.5634 66.2126 27.1952 65.9999 26.8797 65.734C19.2005 58.8747 13.4674 52.5077 9.68047 46.6332C5.89349 40.7565 4 35.2658 4 30.1612C4 22.1852 6.53833 15.831 11.615 11.0986C16.6895 6.3662 22.5667 4 29.2465 4C35.9263 4 41.8035 6.3662 46.8781 11.0986C51.9547 15.831 54.493 22.1852 54.493 30.1612C54.493 35.2658 52.5996 40.7565 48.8126 46.6332C45.0256 52.5077 39.2925 58.8747 31.6134 65.734C31.2978 65.9999 30.9296 66.2126 30.5088 66.3721C30.0881 66.5316 29.6673 66.6114 29.2465 66.6114Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_f_1_1614"
                    x="0"
                    y="0"
                    width="58.4929"
                    height="70.6113"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur
                        stdDeviation="2"
                        result="effect1_foregroundBlur_1_1614"
                    />
                </filter>
            </defs>
        </svg>
    );
});

export default function Isometric({ onComplete = () => { } }) {
    const [currentZone, setCurrentZone] = useState(zonesData[0]);
    const [markerPos, setMarkerPos] = useState(null);
    const navigate = useNavigate()

    const markerRef = useRef(null);
    useEffect(() => {
        if (markerPos) {
            let nodeRef = markerRef.current;
            fadeOutFrame(nodeRef, () => {
                setMarkerPos(null);
                setTimeout(() => {
                    setMarkerPos(currentZone.pos);
                }, 50);
            });
        } else {
            setMarkerPos(currentZone.pos);
        }
    }, [currentZone]);


    return (
        <>
            <div className={styles.overlayScreen}></div>
            <div className={`${styles.container} responsiveHeight`}>
                {/* <Header Isometric /> */}
                <ZoneList setCurrentZone={setCurrentZone} currentZone={currentZone} />
                {/* <ZoneListMob setCurrentZone={setCurrentZone} currentZone={currentZone} /> */}
                <div className={styles.spaceContainerParent}>
                    <div className={styles.spaceContainer}>
                        <img
                            className={styles.spaceImage}
                            src={spaceImage}
                            alt="spaceImage"
                        />
                        {markerPos && (
                            <div className={`${styles.markerHolder}`} style={markerPos}>
                                <Marker ref={markerRef} />
                            </div>
                        )}
                        {/* <div className={styles.bedug}>
                    </div> */}
                    </div>
                </div>
                <div
                    className={`${styles.btn} ${iOS() ? styles.btnIos : styles.btnAndroid
                        } animate__animated  animate__delay-1s animate__fadeInUp`}
                >
                    <CustomButton
                        onClick={(e) => {
                            if (panoromaId.hasOwnProperty(currentZone.id)) {
                                window.location.href = window.location.origin + `/${ROUTES_CONSTANT.home}${panoromaId[currentZone.id]}`
                                // navigate(`/${ROUTES_CONSTANT.home}${panoromaId[currentZone.id]}`)
                                // window.location.reload()
                                // updateLocation(currentZone.id)
                            }
                        }}
                    >
                        Start <RightArrow />
                    </CustomButton>
                </div>
            </div>
        </>
    );
}

const RightArrow = () => (
    <svg viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.26548 21.3344L14.3088 11.0002L4.26548 0.666016H-5.72205e-05L9.62194 11.0002L-5.72205e-05 21.3344H4.26548Z"
            fill="black"
        />
    </svg>
);
