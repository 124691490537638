import React, { useContext, useEffect, useState } from "react";
import {
    Route,
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
    Navigate,
} from "react-router-dom";
import ControlsProvider from "../../Contexts/ControlsProvider";
import { ROUTES_CONSTANT } from "../../constants/RoutesContstants";
import OrientationChecker from "../OrientationChecker";
import Register from "../../scenes/Register";
import MainScreen from "../../scenes/MainScreen";
import Login from "../../scenes/Login";
import Welcome from "../../scenes/Welcome";
import IntroVideo from "../../scenes/IsometericSection";
import Home from "../../scenes/Home";
import UserContextProvider, { UserContext } from "../../Contexts/UserContext/UserContextProvider";
import LocationContextProvider from "../../Contexts/LocationProvider";
import FeedbackTree from "../FeedbackTree";
import CustomNotification from "../CustomNotification/CustomNotification";
import { isIOS, isMobile } from "react-device-detect";
import AdminDash from "../../scenes/Admin-dash";
import { useRef } from "react";
import Countdown from "../Countdown";

export const iOS = () => {
    return (
        [
            "iPad Simulator",
            "iPhone Simulator",
            "iPod Simulator",
            "iPad",
            "iPhone",
            "iPod",
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
};



export const ProtectedRoute = (props) => {
    const { user } = useContext(UserContext);
    const { children } = props;
    // @TODO uncomment after signup
    if (!user) {
        return <Navigate to={"/"} replace />;
    }
    return (

        <ControlsProvider>
            {children}
            <OrientationChecker />
        </ControlsProvider>

    );
    return children;
};

export const AdminRoute = (props) => {
    const { children } = props;
    const { user } = useContext(UserContext)

    // @TODO uncomment after signup
    // if (!user) {
    //     return <Navigate to={"/"} replace />;
    // }
    // if (user.phoneNumber != "+919991004781") {
    //     return <Navigate to={"/"} replace />;
    // }
    return (
        <ControlsProvider>
            {children}
            <OrientationChecker />
        </ControlsProvider>
    );
};

export const UnProtectedRoute = (props) => {
    const { isRoot = false, children, redirect = ROUTES_CONSTANT.welcome } = props;
    const { user } = useContext(UserContext)
    // @TODO uncomment after signup
    if (user && !isRoot) {
        return <Navigate to={`/`} replace />;
    }

    return (
        <>
            {children}
            <OrientationChecker isLandscape />
        </>
    );
};

export default function AppWrapper() {

    useEffect(() => {
        function maintainHeight() {
            let _height = window.innerHeight + 'px'
            let roots = document.querySelector(":root")
            roots.style.setProperty('--responsiveHeight', _height)
        }

        maintainHeight()

        window.addEventListener("resize", maintainHeight)
    }, [])

    const [overlay, setOverlay] = useState(false);
    const overlayref = useRef(true)

    // useEffect(() => {
    //     if (window.location.pathname.includes("home")) {
    //         setOverlay(true)
    //     }
    // }, [window.location.pathname])
    useEffect(() => {
        if (isMobile && !isIOS) {
            // document.documentElement.requestFullscreen();
            document.addEventListener("touchstart", () => {

                var element = document.getElementById("build");
                console.log("fullscreen")

                // make the element go to full-screen mode
                document.documentElement.requestFullscreen();
                // element.requestFullscreen();
            });
        }
        return () => {
            if (isMobile && !isIOS) {

                document.removeEventListener("touchstart", (res) => {
                    console.log(res);
                });
            }
        };
    }, []);

    // useEffect(() => {
    //     if (isMobile && !isIOS) {
    //         function fullscreenMode() {
    //             var element = document.getElementById("root");
    //             // setOverlay(false)
    //             console.log("fullscreen")
    //             // make the element go to full-screen mode
    //             document.documentElement.requestFullscreen();
    //         }
    //         document.addEventListener("touchstart", fullscreenMode);
    //         document.addEventListener("touchend", fullscreenMode);
    //         document.addEventListener("touchcancel", fullscreenMode);
    //         document.addEventListener("touchmove", fullscreenMode);
    //     }
    //     return () => {
    //         if (isMobile && !isIOS) {
    //             document.removeEventListener("touchstart");
    //             document.removeEventListener("touchend");
    //             document.removeEventListener("touchcancel");
    //             document.removeEventListener("touchmove");
    //         }
    //     };
    // }, []);

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/">
                <Route
                    path={"/"}
                    element={
                        <UnProtectedRoute isRoot={true} redirect={ROUTES_CONSTANT.root}>
                            <MainScreen />
                        </UnProtectedRoute>
                    }
                />
                <Route
                    path={ROUTES_CONSTANT.register}
                    element={
                        <UnProtectedRoute redirect={ROUTES_CONSTANT.root}>
                            <Register />
                        </UnProtectedRoute>
                    }
                />
                <Route
                    path={ROUTES_CONSTANT.login}
                    element={
                        <UnProtectedRoute redirect={ROUTES_CONSTANT.root}>
                            <Login />
                        </UnProtectedRoute>
                    }
                />
                <Route
                    path={ROUTES_CONSTANT.welcome}
                    element={
                        <ProtectedRoute redirect={ROUTES_CONSTANT.root}>
                            <Welcome />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ROUTES_CONSTANT.intro}
                    element={
                        <ProtectedRoute redirect={ROUTES_CONSTANT.root}>
                            <IntroVideo />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={ROUTES_CONSTANT.home}
                    element={
                        <ProtectedRoute redirect={ROUTES_CONSTANT.root}>
                            <Home />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={"/admin-dash"}
                    element={
                        <AdminRoute >
                            <AdminDash />
                        </AdminRoute>
                    }
                />

                {/* <Route
                    path={"leaf"}
                    element={
                        <ProtectedRoute redirect={ROUTES_CONSTANT.root}>
                            <FeedbackTree />
                        </ProtectedRoute>
                    }
                /> */}

            </Route>
        )
    );

    const [scrollPos, setScrollPos] = useState(false);
    useEffect(() => {
        window.onscroll = function (e) {
            setScrollPos(e);
            // called when the window is scrolled.
        };
    }, []);

    return (
        <>
            <div
                //please do not remove id
                id="reactContainer"
                style={{
                    position: "relative",
                    zIndex: 5,

                }}
            >
                <div style={{ position: "fixed", top: "1rem", right: "1rem" }}></div>
                {/* {overlay && !isIOS && <div onClick={() => setOverlay(false)} style={{ position: "fixed", inset: 0, zIndex: "10000" }}></div>} */}
                <CustomNotification />
                <RouterProvider router={router} />               
            </div>
            <div id="recaptcha-container"></div>

        </>
    );
}
