import React, { useState } from 'react'

import styles from "./index.module.scss";
import { cloudFunction, realDB } from '../../components/firebase';
import CustomButton from '../../components/CustomButton';
import Header from '../../components/Header';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify'
const XLSX = require("xlsx")

const getUserQuizData = (userId) => {
    return new Promise(async (resolve, reject) => {
        try {
            let nodeRef = realDB.ref(`userQuiz/${userId}`)
            const snapShot = await nodeRef.once('value');
            if (snapShot.exists()) {
                let { score, responses } = snapShot.val()
                let takenQ = []
                if (responses) {
                    let Qent = Object.values(responses)
                    Qent.forEach(q => {
                        if (takenQ.indexOf(q.carType) == -1) {
                            takenQ.push(q.carType)
                        }
                    })
                }
                resolve({
                    quizScore: score,
                    quizTaken: takenQ.length
                })
            } else {
                resolve({
                    quizTaken: 0,
                    quizScore: 0
                })
            }
            return nodeRef
        } catch (error) {
            resolve({
                quizTaken: 0,
                quizScore: 0
            })
            console.error("can't read the data for userid:", userId)
            console.error(error)
        }
    })
}

const downloadExcel = async (date, cb = () => { }) => {
    const startDate = parseInt(date.startDate)
    const endDate = parseInt(date.endDate)
    console.log(date.startDate, "startDate")
    console.log(date.endDate, "Date")
    return new Promise(async (resolve, reject) => {
        try {
            let analyticsNode = realDB.ref(`/analytics_VCC/`).orderByChild("firstLoginTime").startAfter(startDate).endBefore(endDate)
            const snapshot = await analyticsNode.once('value')
            console.log(snapshot.exists())
            console.log(snapshot.val())
            if (snapshot.exists()) {
                let val = snapshot.val();
                let values = Object.values(val)
                let completionPercentage = 50;
                cb(completionPercentage);
                let totalCount = values.length
                let sinleCompletion = 50 / totalCount;
                let rows = []
                let quizData = {}
                for (let i = 0; i < totalCount; i++) {
                    let v = values[i]
                    //Quiz data

                    let _data = {
                        ...v,
                        "loginTimespent(InMin)": v.lastLogoutTime ? ((v.lastLogoutTime - v.firstLoginTime) / 60000) : "",
                        "firstLoginTime": new Date(v.firstLoginTime).toLocaleString(),
                        "lastLoginTime": v.lastLoginTime ? new Date(v.lastLoginTime).toLocaleString() : "",
                        "lastLogoutTime": v.lastLogoutTime ? new Date(v.lastLogoutTime).toLocaleString() : "",
                    }


                    //
                    rows.push(_data)
                    completionPercentage += sinleCompletion
                    cb(completionPercentage);
                }
                // console.log(values)
                console.log(rows[date], rows, "rows")
                const worksheet = XLSX.utils.json_to_sheet(rows, { header: ["name", "email", "firstLoginTime", "lastLoginTime", "lastLogoutTime", "loginTimespent(InMin)", "isMobile", "browserName", "OS"] });
                // const worksheet = XLSX.utils.json_to_sheet(rows, { header: ["sessionId", "userid", "name", "email", "phoneNumber", "loginTime", "logoutTime", "loginTimespent(InMin)", "isMobile", "browserName", "quizTaken", "quizScore"] });
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Analytics");
                XLSX.writeFile(workbook, `G20_Analytics${new Date().getTime()}.xlsx`);
                resolve()
            } else {
                console.log("hello")
            }
        } catch (error) {
            console.error(error)
            reject()
        }
    })
}

const DownloadAnalyticsButton = ({ date }) => {


    const [isLoading, setIsLoading] = useState(false)
    const [downloadPercentage, setDownloadPercentage] = useState(0)
    return (
        <CustomButton
            disabled={isLoading}
            onClick={async () => {
                try {
                    if (!date) {
                        alert(("Please Select a date"))
                        setIsLoading(false)
                        return
                    }
                    setIsLoading(true)
                    setDownloadPercentage(0);
                    await downloadExcel(date, (v) => {
                        setDownloadPercentage(Math.min(Math.round(v), 100));
                    })
                    setIsLoading(false)
                } catch (error) {
                    setIsLoading(false)

                }

            }}>
            {
                isLoading ? `${downloadPercentage} %` : " Download Analytics Data"
            }

        </CustomButton>
    )
}

const downloadShowInterestExcel = (cb = () => { }) => {
    return new Promise(async (resolve, reject) => {
        try {
            let analyticsNode = realDB.ref('/showInterest');
            const snapshot = await analyticsNode.once('value')
            if (snapshot.exists()) {
                let completionPercentage = 50;
                cb(completionPercentage);
                let val = snapshot.val();
                let rows = [];
                let userWiseData = Object.values(val);
                let totalCount = userWiseData.length
                let sinleCompletion = 50 / totalCount;
                userWiseData.forEach(uwd => {
                    let data = Object.values(uwd).map(d => ({
                        ...d,
                        timestamp: new Date(d.timestamp).toLocaleString()
                    }))
                    rows = [...rows, ...data]
                    completionPercentage += sinleCompletion
                    cb(completionPercentage);
                })
                // console.log(values)
                const worksheet = XLSX.utils.json_to_sheet(rows,
                    {
                        header: ["name", "emailId", "phoneNumber", "carType", "state", "city", "pincode", "timestamp"]
                    });
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "ShowInterest");
                XLSX.writeFile(workbook, `Kia_ShowInterest_${new Date().getTime()}.xlsx`);
                resolve()
            }
        } catch (error) {
            console.error(error)
            reject()
        }
    })
}


const DownloadShowInterestData = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [downloadPercentage, setDownloadPercentage] = useState(0)

    return (

        <CustomButton
            disabled={isLoading}
            isLoading={isLoading}
            onClick={async () => {
                try {
                    setIsLoading(true)
                    setDownloadPercentage(0);
                    await downloadShowInterestExcel((v) => {
                        setDownloadPercentage(Math.min(Math.round(v), 100));
                    })
                    setIsLoading(false)
                } catch (error) {
                    setIsLoading(false)

                }

            }}>
            Download Show Interest Data
        </CustomButton>
    )
}
const TABS = [
    {
        label: "Notification",
        link: "./notification-dashboard/index.html"
    },
    {
        label: "Dashboard",
        link: "https://lookerstudio.google.com/embed/reporting/995b4ddf-bd6c-43ac-ac51-48417043b476/page/yFb6D"
    }
]
export default function AdminDash() {
    const [adminUser, setAdminUser] = useState(localStorage.getItem("admin") ?? false);
    const [date, setDate] = useState({
        startDate: null,
        endDate: null
    })


    const handleDateChange = (e) => {
        const format = "YYYY-M-D";
        console.log(e.target.value)
        const dateTime = new Date(e.target.value).getTime(); // moment(e.target.value).format("X")
        console.log(dateTime)
        setDate(prev => ({
            ...prev,
            [e.target.name]: dateTime
        }))
    }

    const handleAdminFormSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target); // Get the form data
        const data = {};

        formData.forEach((value, key) => {
            data[key] = value;
        });

        if (!data["adminpassword"] || data["adminpassword"] !== "Admin@123") {
            toast.error('Please enter valid password', {
                position: "bottom-right",
                autoClose: 5000,
                zIndex: 1000,
                hideProgressBar: false,
                error: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return;
        }
        localStorage.setItem("admin", true)
        setAdminUser(true)

    }

    if (!adminUser) {
        return <div className={styles.container}>
            <Header hideHome showThemeButton={false} />
            <form onSubmit={handleAdminFormSubmit}>
                <div className={styles.inputContainer}>
                    <label htmlFor='adminPassword'>
                        Admin
                    </label>
                    <input name='adminpassword' id='adminPassword' type='password' placeholder='Please enter password' />
                    <CustomButton>Enter</CustomButton>
                </div>
            </form>
            <ToastContainer />
        </div>
    }

    return <div className={styles.container}>
        <Header hideHome showThemeButton={false} />
        <TabComponent />
    </div>

    return (
        <div className={styles.container}>
            <div className={styles.inputWrapper}>
                <div>START DATE -  <input type="datetime-local" name={"startDate"} className={styles.dateInput} onChange={handleDateChange} /></div>
                <div>END DATE -  <input type="datetime-local" name={"endDate"} className={styles.dateInput} onChange={handleDateChange} /></div>
                {/* <div>To- <input type="date" className={styles.dateInput} /></div> */}
            </div>
            <Header hideHome showMenu={false} />
            <DownloadAnalyticsButton date={date} />
            {/* <DownloadShowInterestData /> */}
        </div >
    )
}

const TabComponent = ({ }) => {
    const [activetab, setActiveTab] = useState(0)
    return <>
        <div className={styles.tabContainer}>
            {TABS.map(((tab, index) => <div onClick={() => setActiveTab(index)} className={`${styles.tab} ${activetab === index ? styles.activeTab : ""}`} key={index}>{tab.label}</div>))}
        </div>
        <iframe style={{
            border: 0,
            height: "100%",
            width: "100%", marginTop: "6rem"
        }} src={TABS[activetab].link} />
    </>
}
