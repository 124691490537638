import ReactDOM from "react-dom";
import styles from "./index.module.scss";
import phoneImg from "../../assets/images/phoneImg.png"


const OrientationChecker = ({ isLandscape = false }) => {
    return (
        <>
            {ReactDOM.createPortal(
                <div
                    id={styles.rotate}
                    className={`${isLandscape ? styles.landscapeRotate : styles.portraitRotate
                        }`}
                >
                    <div className={styles.phone}>
                        <img src={phoneImg} alt="" />
                    </div>
                    <div className={styles.message}>
                        {isLandscape ? (
                            <>
                                Please <b>rotate your phone </b>
                            </>
                        ) : (
                            <>
                                Please <b>rotate your phone to landscape mode</b> for a better
                                experience
                            </>
                        )}
                    </div>
                </div>,
                document.getElementById("orientationModal")
            )}
        </>
    );
};

export default OrientationChecker;
