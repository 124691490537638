import { panoromaId } from "../../constants/PanoramaConstants";

export const zonesData = [
    // {
    //     id: "g20wall",
    //     label: "G20 Wall",
    //     pos: {
    //         margin: "18% 22%",
    //     },
    //     panoromaId: panoromaId.g20wall,

    // },
    {
        id: "parrotlady",
        label: "Cultural Heritage",
        pos: {
            margin: "7% 15%"
        },
        panoromaId: panoromaId.parrotlady,
    },
    {
        id: "dancingganesha",
        label: "Repatriation of Cultural Property",
        pos: {
            margin: "1% 1%",
        },
        panoromaId: panoromaId.dancingganesha,
    },
    {
        id: "historical",
        label: "Historical Precedents",
        pos: {
            margin: "11% -4.5%"
        },
        panoromaId: panoromaId.historical,
    },
    {
        id: "conventionAndGuiding",
        label: "Convention and Guiding Principles",
        pos: {
            margin: "-2% -23.5%",
        },
        panoromaId: panoromaId.conventionAndGuiding,
    },
    {
        id: "specs",
        label: "Global Cooperation",
        pos: {
            margin: "-14.25% -33%",
        },
        panoromaId: panoromaId.specs,
    },

    {
        id: "amin",
        label: "Glimpses of the Return",
        pos: {
            margin: "-19% -22%",
        },
        panoromaId: panoromaId.amin,
    },
    // {
    //     id: "holo",
    //     label: "Hologram Experience",
    //     pos: {
    //         margin: "1% 37%",
    //     },
    //     panoromaId: panoromaId.holo,
    // },
    {
        id: "tree",
        label: "Signature Wall",
        pos: {
            margin: "5% 36%",
        },
        panoromaId: panoromaId.tree,
    },
];