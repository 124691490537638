import styles from "./index.module.scss";

const CustomButton = (props) => {
    const {
        children,
        className = "",
        style = {},
        disabled = false,
        onClick,
        isLoading = false,
    } = props;
    return (
        <button
            className={`${styles.container} ${className} animate__animated animate__fadeIn`}
            style={{ ...style, background: isLoading ? '#848484' : "#fff" }}
            disabled={isLoading}
            onClick={onClick}
        >
            {isLoading ? (
                <img
                    src={require("../../assets/gif/loader.gif")}
                    // height="50%"
                    height="20rem"
                    alt=""
                />
            ) : (
                children
            )}
        </button>
    );
};

export default CustomButton;
