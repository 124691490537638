import { useState } from "react";
import { Leaf_Type } from "..";
import styles from "./index.module.scss";
import greenLeaf from "../../../assets/images/leaf/greenLeafTut.png";
import yellowLeaf from "../../../assets/images/leaf/yellowLeafTut.png";

const TutorialScreen = () => {
  const [activeItem, setActiveItem] = useState(
    sessionStorage.getItem("signatureWallTut") == "true"
      ? null
      : Leaf_Type.YELLOW
  );

  const handleNextButton = () => {
    setActiveItem((prev) => {
      if (prev === Leaf_Type.YELLOW) {
        return Leaf_Type.GREEN;
      }
      sessionStorage.setItem("signatureWallTut", "true");
      return null;
    });
  };

  return (
    <div
      className={`${styles.container} ${
        activeItem === null ? styles.modalFadeOut : styles.modalFadeIn
      }`}
    >
      <div
        className={`${styles.leaf} ${styles.greenLeaf} ${
          activeItem === Leaf_Type.GREEN ? styles.fadeIn : styles.fadeOut
        }`}
      >
        <img src={greenLeaf} alt="" />
        <div className={styles.text}>
          Tap on the green leaf to
          <br />
          view others feedback
        </div>
      </div>
      <div
        className={`${styles.leaf} ${styles.yellowLeaf} ${
          activeItem === Leaf_Type.YELLOW ? styles.fadeIn : styles.fadeOut
        }`}
      >
        <img src={yellowLeaf} alt="" />
        <div className={styles.text}>
          Tap on the yellow leaf to
          <br />
          write your feedback
        </div>
      </div>
      <button
        onClick={handleNextButton}
        className={`treeButton ${styles.nextButton}`}
      >
        Next
      </button>
    </div>
  );
};

export default TutorialScreen;
