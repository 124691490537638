import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import "reactjs-popup/dist/index.css";
import styles from "./index.module.scss"
import Modal360 from '../../components/Modal360';
import modalTut from "../../assets/images/modalTut.png"
import OverlayTutorial from '../../components/OverlayTutorial';
import { ROUTES_CONSTANT } from '../../constants/RoutesContstants';
import { panoromaId } from '../../constants/PanoramaConstants';
import { useNavigate } from 'react-router-dom';
import FeedbackTree from '../../components/FeedbackTree';
import { useContext } from 'react';
import { LocationContext } from '../../Contexts/LocationProvider';
import { Relic3dModals } from './modalsData';
import { useRef } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import mobileNav from "../../assets/images/mobileNav.jpg"
import { UserContext } from '../../Contexts/UserContext/UserContextProvider';
import { addCustomRealtimeHotspotAnalytics, addGAAnalytics, addRealtimeHotspotAnalytics } from '../../components/firebase';
import { zonesData } from '../../components/Isometric/data';

const Home = () => {

    const [videoModal, setVideoModal] = useState({ open: false, url: "" });
    const [showRelic, setShowRelic] = useState({ open: false, modalData: "" })
    const [showModalTut, setShowModalTut] = useState(false)
    const [showInfo, setShowInfo] = useState(true)
    const [loaded, setLoaded] = useState(true);
    const [progress, setProgress] = useState(0);
    const [tree, setShowTree] = useState(false);
    const [poster, setPoster] = useState({ show: false, url: null })
    const modalRef = useRef(false)
    const navigation = useNavigate()
    const [overlay, setOverlay] = useState(true);
    const { user } = useContext(UserContext)
    const { updateLocation } = useContext(LocationContext)

    const [showWall, setShowWall] = useState(false)

    useEffect(() => {
        if (isMobile && !isIOS) {
            // document.documentElement.requestFullscreen();
            document.addEventListener("touchstart", () => {

                var element = document.getElementById("build");
                setOverlay(false)
                // make the element go to full-screen mode
                document.documentElement.requestFullscreen();
                // element.requestFullscreen();
            });
        }
        return () => {
            if (isMobile && !isIOS) {

                document.removeEventListener("touchstart", (res) => {
                    console.log(res);
                });
            }
        };
    }, []);



    useEffect(() => {
        let _hash = window.location.hash.split("=")[1]
        let newLocaiton = null
        zonesData.map(zone => {
            let zonePanoramaSplit = zone.panoromaId.split("=")[1]
            if (zonePanoramaSplit == _hash) {
                newLocaiton = zone
            }
        })
        if (newLocaiton) {
            updateLocation(newLocaiton.id)
        }
    }, [])

    useEffect(() => {
        setLoaded(true);
        const interval = setInterval(() => {
            if (progress <= 100) {
                setProgress((progress) => progress + 5);
            } else {
                setLoaded(false);
                clearInterval(interval);
            }
        }, 50);
        return () => clearInterval(interval);
    }, [progress]);

    const handleClose = () => {
        setShowRelic({ open: false, modalData: {} })
        setShowWall(false)
    }


    const handleCloseIframe = () => {
        setPoster({ show: false, url: null })
    }


    useEffect(() => {
        if (window.loadVista) window.loadVista();
        window.parent.openOrb = (id) => {

            if (panoromaId.hasOwnProperty(id)) {
                navigation(`/${ROUTES_CONSTANT.home}${panoromaId[id]}`, { replace: true })
                zonesData.map(zone => {
                    if (zone.id === id) {
                        updateLocation(id)
                    }
                })
            }
        }

        const kioskAnalyticsId = {
            interpol1: "Conventions_And_Guiding_Principles",
            interpol2: "Interpol",
            redposter: "Internation_Council_Of_Museums"
        }

        window.parent.kioskclick = (id) => {

            addCustomRealtimeHotspotAnalytics(user, kioskAnalyticsId[id])
            addGAAnalytics(kioskAnalyticsId[id], user)

            if (id === "interpol1") {
                setPoster({
                    show: true,
                    url: "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F3O4oH9qQOdxiO9MqmPiEOp%2FG20-virtual-platform%3Fpage-id%3D0%253A1%26node-id%3D1-46%26viewport%3D485%252C181%252C0.09%26t%3DFtq2QimjUjHOSsuJ-1%26scaling%3Dcontain%26starting-point-node-id%3D1%253A46%26&hide-ui=1"
                })
            }

            // if (id === "redposter") {
            //     setPoster({
            //         show: true,
            //         url: "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FcVkFd1Y3nU41CddaEazZ58%2FUntitled%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D1-5%26viewport%3D-256%252C1648%252C0.19%26scaling%3Dcontain%26starting-point-node-id%3D1%253A5&hide-ui=1"
            //     })
            // }
            if (id === "interpol2") {
                setPoster({
                    show: true,
                    url: "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F3O4oH9qQOdxiO9MqmPiEOp%2FG20-virtual-platform%3Fpage-id%3D0%253A1%26node-id%3D2-731%26viewport%3D485%252C181%252C0.09%26t%3DFtq2QimjUjHOSsuJ-1%26scaling%3Dcontain%26starting-point-node-id%3D1%253A7%26&hide-ui=1"
                })
            }

        }
        window.parent.posterclick = (id) => {
            console.log(id, "id")
            addGAAnalytics(id, user)
            addCustomRealtimeHotspotAnalytics(user, id)
            if (id == "tree") {
                setShowTree(true)
            }
            // let url = ["https://player.vimeo.com/video/782211933"]
            // if (ScreenVideoData[videoId]) {
            //     url = ScreenVideoData[videoId]
            // }
            // alert('videoId: ' + videoId)
            // setShowRelic({
            //     open: true,
            //     url: ""
            // });
        }



        window.parent.relicclick = (id) => {
            let relicId = Relic3dModals[id].relicText.heading.replace(" ", "_")
            console.log(relicId, "relicid ")
            addGAAnalytics(relicId, user)

            addCustomRealtimeHotspotAnalytics(user, relicId)
            if (!modalRef.current) {

                setShowModalTut(true)
                modalRef.current = true
            }
            // console.log(id

            // if (id === "parrot") {
            //     setShowWall(true)
            // } else {
            //     setShowWall(false)
            //     // alert('videoId: ' + videoId)

            // }

            if (Relic3dModals.hasOwnProperty(id)) {

                setShowRelic({
                    open: true,
                    modalData: Relic3dModals[id]
                });
            }
            // let url = ["https://player.vimeo.com/video/782211933"]
            // if (ScreenVideoData[videoId]) {
            //     url = ScreenVideoData[videoId]
            // }

        }


    })
    let isFullScreen = false;

    const handleShowInfo = () => {
        setShowInfo(true)
    }

    return (
        <div id='build' style={{ position: "relative" }}>

            <Header />
            {
                poster.show && <>
                    <div onClick={handleCloseIframe} className={styles.closeIframe}>
                        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.90588 0.324361L6.39124 4.96501L10.8534 0.348406C10.9519 0.239864 11.0707 0.153035 11.2024 0.0931246C11.3342 0.033214 11.4763 0.00145624 11.6203 -0.000244141C11.9285 -0.000244141 12.224 0.12642 12.4419 0.351884C12.6599 0.577348 12.7823 0.883143 12.7823 1.202C12.785 1.34939 12.7586 1.49581 12.7046 1.63226C12.6507 1.76871 12.5703 1.89231 12.4686 1.99548L7.94833 6.61208L12.4686 11.2888C12.6601 11.4827 12.7724 11.7452 12.7823 12.0222C12.7823 12.341 12.6599 12.6468 12.4419 12.8723C12.224 13.0977 11.9285 13.2244 11.6203 13.2244C11.4722 13.2308 11.3244 13.2052 11.1864 13.1493C11.0484 13.0934 10.9231 13.0085 10.8185 12.8998L6.39124 8.25916L1.9175 12.8878C1.81932 12.9927 1.70203 13.0765 1.57239 13.1343C1.44275 13.192 1.30334 13.2227 1.1622 13.2244C0.854014 13.2244 0.558453 13.0977 0.340534 12.8723C0.122615 12.6468 0.000189067 12.341 0.000189067 12.0222C-0.00252015 11.8748 0.023912 11.7284 0.0778635 11.5919C0.131815 11.4555 0.212148 11.3319 0.313932 11.2287L4.83415 6.61208L0.313932 1.93536C0.122415 1.74151 0.0101122 1.47901 0.000189067 1.202C0.000189067 0.883143 0.122615 0.577348 0.340534 0.351884C0.558453 0.12642 0.854014 -0.000244141 1.1622 -0.000244141C1.44108 0.00336258 1.70834 0.11998 1.90588 0.324361Z" fill="white" />
                        </svg>
                    </div><div className={styles.iframeOverlay}>

                    </div><iframe className={`${styles.iframe} animate__animated animate__fadeIn`} src={poster.url} allowFullScreen></iframe></>
            }
            {
                loaded && (
                    <OverlayTutorial progress={progress} />
                )
            }
            {tree && <FeedbackTree setShowTree={setShowTree} />}
            {
                showRelic.open && <div
                    className={`${styles.modal3DContainer} animate__animated animate__fadeIn`}
                >
                    {showInfo && <div className={styles.modalDesc}>
                        <svg onClick={() => setShowInfo(false)} width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.90588 0.324361L6.39124 4.96501L10.8534 0.348406C10.9519 0.239864 11.0707 0.153035 11.2024 0.0931246C11.3342 0.033214 11.4763 0.00145624 11.6203 -0.000244141C11.9285 -0.000244141 12.224 0.12642 12.4419 0.351884C12.6599 0.577348 12.7823 0.883143 12.7823 1.202C12.785 1.34939 12.7586 1.49581 12.7046 1.63226C12.6507 1.76871 12.5703 1.89231 12.4686 1.99548L7.94833 6.61208L12.4686 11.2888C12.6601 11.4827 12.7724 11.7452 12.7823 12.0222C12.7823 12.341 12.6599 12.6468 12.4419 12.8723C12.224 13.0977 11.9285 13.2244 11.6203 13.2244C11.4722 13.2308 11.3244 13.2052 11.1864 13.1493C11.0484 13.0934 10.9231 13.0085 10.8185 12.8998L6.39124 8.25916L1.9175 12.8878C1.81932 12.9927 1.70203 13.0765 1.57239 13.1343C1.44275 13.192 1.30334 13.2227 1.1622 13.2244C0.854014 13.2244 0.558453 13.0977 0.340534 12.8723C0.122615 12.6468 0.000189067 12.341 0.000189067 12.0222C-0.00252015 11.8748 0.023912 11.7284 0.0778635 11.5919C0.131815 11.4555 0.212148 11.3319 0.313932 11.2287L4.83415 6.61208L0.313932 1.93536C0.122415 1.74151 0.0101122 1.47901 0.000189067 1.202C0.000189067 0.883143 0.122615 0.577348 0.340534 0.351884C0.558453 0.12642 0.854014 -0.000244141 1.1622 -0.000244141C1.44108 0.00336258 1.70834 0.11998 1.90588 0.324361Z" fill="white" />
                        </svg>
                        <div className={styles.title}>{showRelic?.modalData?.relicText?.heading}</div>
                        <div className={styles.accNo}><span>{showRelic?.modalData?.relicText?.subHeading.length > 0 && "Acc No. - "}</span>{showRelic?.modalData?.relicText?.subHeading}</div>
                        {showRelic?.modalData?.objectLabel && <div className={styles.bolderObject}>Object Label:</div>}
                        <div className={styles.origin}>{showRelic?.modalData?.relicText?.origin}</div>
                        <div className={styles.material}>{showRelic?.modalData?.relicText?.material}</div>
                        <div className={styles.country}>{showRelic?.modalData?.relicText?.country}</div>
                    </div>}
                    <><div onClick={handleShowInfo} className={styles.info}>
                        <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25.5 38.25C26.2225 38.25 26.8285 38.0052 27.3181 37.5156C27.8077 37.026 28.0517 36.4208 28.05 35.7V25.5C28.05 24.7775 27.8052 24.1714 27.3156 23.6818C26.826 23.1922 26.2208 22.9483 25.5 22.95C24.7775 22.95 24.1714 23.1948 23.6818 23.6844C23.1922 24.174 22.9483 24.7792 22.95 25.5V35.7C22.95 36.4225 23.1948 37.0285 23.6844 37.5181C24.174 38.0077 24.7792 38.2517 25.5 38.25ZM25.5 17.85C26.2225 17.85 26.8285 17.6052 27.3181 17.1156C27.8077 16.626 28.0517 16.0208 28.05 15.3C28.05 14.5775 27.8052 13.9714 27.3156 13.4818C26.826 12.9922 26.2208 12.7483 25.5 12.75C24.7775 12.75 24.1714 12.9948 23.6818 13.4844C23.1922 13.974 22.9483 14.5792 22.95 15.3C22.95 16.0225 23.1948 16.6285 23.6844 17.1181C24.174 17.6077 24.7792 17.8517 25.5 17.85ZM25.5 51C21.9725 51 18.6575 50.3302 15.555 48.9906C12.4525 47.651 9.75375 45.8345 7.45875 43.5412C5.16375 41.2462 3.3473 38.5475 2.0094 35.445C0.6715 32.3425 0.0017 29.0275 0 25.5C0 21.9725 0.6698 18.6575 2.0094 15.555C3.349 12.4525 5.16545 9.75375 7.45875 7.45875C9.75375 5.16375 12.4525 3.3473 15.555 2.0094C18.6575 0.6715 21.9725 0.0017 25.5 0C29.0275 0 32.3425 0.6698 35.445 2.0094C38.5475 3.349 41.2462 5.16545 43.5412 7.45875C45.8362 9.75375 47.6535 12.4525 48.9931 15.555C50.3327 18.6575 51.0017 21.9725 51 25.5C51 29.0275 50.3302 32.3425 48.9906 35.445C47.651 38.5475 45.8345 41.2462 43.5412 43.5412C41.2462 45.8362 38.5475 47.6535 35.445 48.9931C32.3425 50.3327 29.0275 51.0017 25.5 51Z" fill="white" />
                        </svg>

                    </div>
                        {showModalTut &&
                            <> <div className={styles.modalTutOverlay}></div><div className={`${styles.modalTut} animate__animated animate__fadeIn`}>
                                <div onClick={() => setShowModalTut(false)} className={styles.tutClose}>
                                    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.90588 0.324361L6.39124 4.96501L10.8534 0.348406C10.9519 0.239864 11.0707 0.153035 11.2024 0.0931246C11.3342 0.033214 11.4763 0.00145624 11.6203 -0.000244141C11.9285 -0.000244141 12.224 0.12642 12.4419 0.351884C12.6599 0.577348 12.7823 0.883143 12.7823 1.202C12.785 1.34939 12.7586 1.49581 12.7046 1.63226C12.6507 1.76871 12.5703 1.89231 12.4686 1.99548L7.94833 6.61208L12.4686 11.2888C12.6601 11.4827 12.7724 11.7452 12.7823 12.0222C12.7823 12.341 12.6599 12.6468 12.4419 12.8723C12.224 13.0977 11.9285 13.2244 11.6203 13.2244C11.4722 13.2308 11.3244 13.2052 11.1864 13.1493C11.0484 13.0934 10.9231 13.0085 10.8185 12.8998L6.39124 8.25916L1.9175 12.8878C1.81932 12.9927 1.70203 13.0765 1.57239 13.1343C1.44275 13.192 1.30334 13.2227 1.1622 13.2244C0.854014 13.2244 0.558453 13.0977 0.340534 12.8723C0.122615 12.6468 0.000189067 12.341 0.000189067 12.0222C-0.00252015 11.8748 0.023912 11.7284 0.0778635 11.5919C0.131815 11.4555 0.212148 11.3319 0.313932 11.2287L4.83415 6.61208L0.313932 1.93536C0.122415 1.74151 0.0101122 1.47901 0.000189067 1.202C0.000189067 0.883143 0.122615 0.577348 0.340534 0.351884C0.558453 0.12642 0.854014 -0.000244141 1.1622 -0.000244141C1.44108 0.00336258 1.70834 0.11998 1.90588 0.324361Z" fill="white" />
                                    </svg>
                                </div>
                                {/* <iframe width="400" height="250" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FcVkFd1Y3nU41CddaEazZ58%2FUntitled%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D1-5%26viewport%3D-256%252C1648%252C0.19%26scaling%3Dscaledown%26starting-point-node-id%3D1%253A5&hide-ui=1" allowfullscreen></iframe> */}
                                <img src={isMobile ? mobileNav : modalTut} alt="" />
                            </div></>}
                        <Modal360 modal={showRelic.modalData.model}
                            brightnessNeeded={showRelic.modalData.brightnessNeeded}
                            modalPosition={{ x: 0, y: 0, z: 0 }}
                            modalRotation={{ x: 0, y: 0, z: 0 }}
                            cameraFOV={20}
                            cameraOffset={20}
                            cameraPOSITION={{ x: 0.8627723359886135, y: 0, z: -0.07451509767245712 }}
                        />
                    </>
                    <div onClick={handleClose} className={styles.close}>
                        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.90588 0.324361L6.39124 4.96501L10.8534 0.348406C10.9519 0.239864 11.0707 0.153035 11.2024 0.0931246C11.3342 0.033214 11.4763 0.00145624 11.6203 -0.000244141C11.9285 -0.000244141 12.224 0.12642 12.4419 0.351884C12.6599 0.577348 12.7823 0.883143 12.7823 1.202C12.785 1.34939 12.7586 1.49581 12.7046 1.63226C12.6507 1.76871 12.5703 1.89231 12.4686 1.99548L7.94833 6.61208L12.4686 11.2888C12.6601 11.4827 12.7724 11.7452 12.7823 12.0222C12.7823 12.341 12.6599 12.6468 12.4419 12.8723C12.224 13.0977 11.9285 13.2244 11.6203 13.2244C11.4722 13.2308 11.3244 13.2052 11.1864 13.1493C11.0484 13.0934 10.9231 13.0085 10.8185 12.8998L6.39124 8.25916L1.9175 12.8878C1.81932 12.9927 1.70203 13.0765 1.57239 13.1343C1.44275 13.192 1.30334 13.2227 1.1622 13.2244C0.854014 13.2244 0.558453 13.0977 0.340534 12.8723C0.122615 12.6468 0.000189067 12.341 0.000189067 12.0222C-0.00252015 11.8748 0.023912 11.7284 0.0778635 11.5919C0.131815 11.4555 0.212148 11.3319 0.313932 11.2287L4.83415 6.61208L0.313932 1.93536C0.122415 1.74151 0.0101122 1.47901 0.000189067 1.202C0.000189067 0.883143 0.122615 0.577348 0.340534 0.351884C0.558453 0.12642 0.854014 -0.000244141 1.1622 -0.000244141C1.44108 0.00336258 1.70834 0.11998 1.90588 0.324361Z" fill="white" />
                        </svg>
                    </div>

                </div>
            }
        </div >
    )
}

export default Home