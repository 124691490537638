import React, { useState } from 'react'
import styles from "./index.module.scss"
import ZoneList from '../Isometric/SubComp/ZoneList'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { ROUTES_CONSTANT } from '../../constants/RoutesContstants'
import { useContext } from 'react'
import UserContextProvider, { UserContext } from '../../Contexts/UserContext/UserContextProvider'
import { addLogoutAnalytics, signOut } from '../firebase'
import { LocationContext } from '../../Contexts/LocationProvider'
import { zonesData } from '../Isometric/data'


const menuList = [
    {
        name: "Home",
        icon: () => {
            return (
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.65014 15.0391V10.2357H10.3458V15.0391C10.3458 15.5675 10.7615 15.9998 11.2697 15.9998H14.0414C14.5495 15.9998 14.9653 15.5675 14.9653 15.0391V8.31438H16.5359C16.9609 8.31438 17.1642 7.76679 16.8408 7.47859L9.11696 0.244727C8.76588 -0.0819013 8.23001 -0.0819013 7.87893 0.244727L0.155079 7.47859C-0.159049 7.76679 0.0349716 8.31438 0.459968 8.31438H2.03061V15.0391C2.03061 15.5675 2.44637 15.9998 2.95451 15.9998H5.72623C6.23438 15.9998 6.65014 15.5675 6.65014 15.0391Z" fill="white" />
                </svg>
            )
        },
        location: ROUTES_CONSTANT.welcome
    },
    {
        name: "Themes",
        icon: () => {
            return (
                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 -0.000244141C8.34205 -0.000244141 6.1875 2.20327 6.1875 4.92163C6.1875 7.11929 9.33281 11.1248 10.5378 12.5787C10.7819 12.8732 11.2185 12.8732 11.4622 12.5787C12.6672 11.1248 15.8125 7.11929 15.8125 4.92163C15.8125 2.20327 13.658 -0.000244141 11 -0.000244141ZM11 6.56226C10.1139 6.56226 9.39583 5.82788 9.39583 4.92163C9.39583 4.01538 10.1139 3.28101 11 3.28101C11.8861 3.28101 12.6042 4.01538 12.6042 4.92163C12.6042 5.82788 11.8861 6.56226 11 6.56226ZM0.768472 8.4353C0.541665 8.52808 0.347237 8.68822 0.210264 8.89508C0.0732908 9.10194 5.43204e-05 9.34602 0 9.59585L0 19.374C0 19.8162 0.436563 20.1185 0.837986 19.9544L6.11111 17.4998V8.39507C5.77347 7.77085 5.49733 7.16304 5.29948 6.58179L0.768472 8.4353ZM11 14.0494C10.4626 14.0494 9.95424 13.808 9.60552 13.3869C8.85462 12.4806 8.05597 11.4486 7.33333 10.39V17.4994L14.6667 19.9994V10.3904C13.944 11.4486 13.1458 12.481 12.3945 13.3873C12.0458 13.808 11.5374 14.0494 11 14.0494ZM21.162 6.29507L15.8889 8.74976V19.9998L21.2315 17.8142C21.4584 17.7215 21.6528 17.5613 21.7898 17.3545C21.9268 17.1476 22 16.9035 22 16.6537V6.87554C22 6.43335 21.5634 6.13101 21.162 6.29507Z" fill="white" />
                </svg>
            )
        },
        location: ROUTES_CONSTANT.intro
    },
    {
        name: "Log Out",
        icon: () => {
            return (
                <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.707 0.422106C6 1.07084 6 2.2576 6 4.6301L6 16.0611C6 18.4336 6 19.6203 6.707 20.2691C7.414 20.9178 8.495 20.7233 10.657 20.3332L12.987 19.9132C15.381 19.4807 16.578 19.2644 17.289 18.356C18 17.4465 18 16.1325 18 13.5034L18 7.18779C18 4.55974 18 3.24571 17.29 2.33624C16.578 1.4278 15.38 1.21156 12.986 0.780101L10.658 0.358992C8.496 -0.0310775 7.415 -0.225595 6.708 0.423141L6.707 0.422106ZM9 8.45112C9.414 8.45112 9.75 8.81429 9.75 9.2623L9.75 11.4289C9.75 11.8769 9.414 12.2401 9 12.2401C8.586 12.2401 8.25 11.8769 8.25 11.4289L8.25 9.2623C8.25 8.81429 8.586 8.45112 9 8.45112Z" fill="white" />
                    <path d="M4.547 2.58594C2.489 2.58904 1.416 2.6356 0.732 3.34331C5.52158e-08 4.10069 -1.15485e-07 5.31953 -2.18557e-07 7.75927L-4.37114e-07 12.9326C-5.40142e-07 15.3713 -4.72424e-07 16.5902 0.731999 17.3486C1.416 18.0552 2.489 18.1028 4.547 18.1059C4.5 17.4603 4.5 16.7153 4.5 15.9093L4.5 4.78254C4.5 3.9755 4.5 3.23054 4.547 2.58594Z" fill="white" />
                </svg>
            )
        },
        location: ROUTES_CONSTANT.root
    },
]


const Header = ({showThemeButton=true}) => {

    const [showMenu, setShowMenu] = useState(false)
    const [zoneList, setzoneList] = useState(false)
    const [currentZone, setCurrentZone] = useState(false)
    const { user } = useContext(UserContext)

    const navigation = useNavigate()

    const handleMenuClick = (location) => {
        if (location == ROUTES_CONSTANT.root) {
            addLogoutAnalytics(user)
            signOut()
        } else {
            window.location.hash = ""
            navigation(`/${location}`, { replace: true })
        }
    }

    useEffect(() => {
        if (currentZone) {
            window.location.hash = currentZone.panoromaId
        }
    }, [currentZone])

    const handleMenu = () => {
        setShowMenu(true)
    }

    const handleClose = () => {
        setShowMenu(false)
    }

    const handleZone = () => {
        setzoneList(true)
    }







    return (
        <div className={styles.headerWrapper}>
            {showThemeButton&&<div className={styles.zonesIcon}>
                {zoneList ?
                    <div className={`${styles.zoneBox} animate__animated animate__slideInLeft`}> <ZoneList setzoneList={setzoneList} isHome={true} currentZone={currentZone} setCurrentZone={setCurrentZone} /> </div> :
                    <svg className='animate__animated animate__slideInLeft' onClick={handleZone} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_b_94_698)">
                            <circle cx="18" cy="17.9998" r="18" fill="#182033" fillOpacity="0.8" />
                            <circle cx="18" cy="17.9998" r="17.75" stroke="white" strokeWidth="0.5" />
                        </g>
                        <path d="M14.3779 9.41904C14.6298 9.13952 14.9504 8.99976 15.3397 8.99976C15.729 8.99976 16.0496 9.13952 16.3015 9.41904L22.6221 16.4325C22.7595 16.585 22.8566 16.7501 22.9134 16.928C22.9702 17.1059 22.9991 17.2965 23 17.4998C23 17.703 22.9656 17.9002 22.8969 18.0913C22.8282 18.2824 22.7366 18.441 22.6221 18.567L16.3015 25.5805C16.0496 25.86 15.729 25.9998 15.3397 25.9998C14.9504 25.9998 14.6298 25.86 14.3779 25.5805C14.126 25.3009 14 24.9452 14 24.5132C14 24.0812 14.126 23.7255 14.3779 23.4459L19.7366 17.4998L14.3779 11.5536C14.126 11.274 14 10.9183 14 10.4863C14 10.0543 14.126 9.69856 14.3779 9.41904Z" fill="white" />
                        <defs>
                            <filter id="filter0_b_94_698" x="-24" y="-24.0002" width="84" height="84" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
                                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_94_698" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_94_698" result="shape" />
                            </filter>
                        </defs>
                    </svg>
                }
            </div>}
            <div className={styles.menuIcon}>
                {showMenu ?
                    <div className={`${styles.menuBox} animate__animated animate__slideInRight`}>
                        <div onClick={handleClose} className={styles.close}>
                            <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.90588 0.324361L6.39124 4.96501L10.8534 0.348406C10.9519 0.239864 11.0707 0.153035 11.2024 0.0931246C11.3342 0.033214 11.4763 0.00145624 11.6203 -0.000244141C11.9285 -0.000244141 12.224 0.12642 12.4419 0.351884C12.6599 0.577348 12.7823 0.883143 12.7823 1.202C12.785 1.34939 12.7586 1.49581 12.7046 1.63226C12.6507 1.76871 12.5703 1.89231 12.4686 1.99548L7.94833 6.61208L12.4686 11.2888C12.6601 11.4827 12.7724 11.7452 12.7823 12.0222C12.7823 12.341 12.6599 12.6468 12.4419 12.8723C12.224 13.0977 11.9285 13.2244 11.6203 13.2244C11.4722 13.2308 11.3244 13.2052 11.1864 13.1493C11.0484 13.0934 10.9231 13.0085 10.8185 12.8998L6.39124 8.25916L1.9175 12.8878C1.81932 12.9927 1.70203 13.0765 1.57239 13.1343C1.44275 13.192 1.30334 13.2227 1.1622 13.2244C0.854014 13.2244 0.558453 13.0977 0.340534 12.8723C0.122615 12.6468 0.000189067 12.341 0.000189067 12.0222C-0.00252015 11.8748 0.023912 11.7284 0.0778635 11.5919C0.131815 11.4555 0.212148 11.3319 0.313932 11.2287L4.83415 6.61208L0.313932 1.93536C0.122415 1.74151 0.0101122 1.47901 0.000189067 1.202C0.000189067 0.883143 0.122615 0.577348 0.340534 0.351884C0.558453 0.12642 0.854014 -0.000244141 1.1622 -0.000244141C1.44108 0.00336258 1.70834 0.11998 1.90588 0.324361Z" fill="white" />
                            </svg>

                        </div>
                        <div className={styles.userDetails}>
                            <div className={styles.userInitial}>{user?.name?.charAt(0).toUpperCase()}</div>
                            <div className={styles.NameWrapper}>
                                <div className={styles.name}>{user?.name?.split(" ")[0]}</div>
                                <div className={styles.email}>{user?.email}</div>
                            </div>
                        </div>
                        <div className={styles.menuList}>
                            {menuList.map((menu,index) => {
                                return <div key={index} onClick={() => handleMenuClick(menu.location)} className={styles.menuItem}>
                                    <div className={styles.svgIcon}>{menu.icon()}</div>
                                    {menu.name}

                                </div>
                            })}
                        </div>
                    </div>
                    : <svg className='animate__animated animate__slideInRight' onClick={handleMenu} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_b_94_695)">
                            <circle cx="18" cy="18" r="18" fill="#182033" fillOpacity="0.8" />
                            <circle cx="18" cy="18" r="17.75" stroke="white" strokeWidth="0.5" />
                        </g>
                        <path d="M23.25 21.75L12.75 21.75C11.9228 21.75 11.25 22.4228 11.25 23.25C11.25 24.0773 11.9228 24.75 12.75 24.75L23.25 24.75C24.0773 24.75 24.75 24.0773 24.75 23.25C24.75 22.4227 24.0773 21.75 23.25 21.75ZM23.25 16.5L12.75 16.5C11.9228 16.5 11.25 17.1728 11.25 18C11.25 18.8273 11.9228 19.5 12.75 19.5L23.25 19.5C24.0773 19.5 24.75 18.8272 24.75 18C24.75 17.1727 24.0773 16.5 23.25 16.5ZM23.25 11.25L12.75 11.25C11.9227 11.25 11.25 11.9227 11.25 12.75C11.25 13.5773 11.9228 14.25 12.75 14.25L23.25 14.25C24.0773 14.25 24.75 13.5772 24.75 12.75C24.75 11.9227 24.0773 11.25 23.25 11.25Z" fill="white" />
                        <defs>
                            <filter id="filter0_b_94_695" x="-24" y="-24" width="84" height="84" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
                                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_94_695" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_94_695" result="shape" />
                            </filter>
                        </defs>
                    </svg>}

            </div>
        </div>
    )
}

export default Header