import firebase, { apps, database } from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/analytics";
import "firebase/compat/storage";
import "firebase/compat/functions";
import uniqid from "uniqid";

import { browserName, isMobile, osName } from "react-device-detect";

let firebaseConfig = {
  apiKey: "AIzaSyDtaSnZJ7Tbb_Cfy4umUbqa7SU4MQ5o-wk",
  authDomain: "g20-platformjuly2024.firebaseapp.com",
  databaseURL:
    "https://g20-platformjuly2024-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "g20-platformjuly2024",
  storageBucket: "g20-platformjuly2024.appspot.com",
  messagingSenderId: "351040765286",
  appId: "1:351040765286:web:8a9de61354e2bde3eb9ca7",
  measurementId: "G-KWRMG3XKL5",
};

firebase.initializeApp(firebaseConfig);

window.firebaseInstance = firebase;

export const firebaseApp = firebase;

export const auth = firebase.auth();

export const firestore = firebase.firestore();

export const realDB = firebase.database();

export const analytics = firebase.analytics();

export const storage = firebase.storage();

// export const cloudFunction = firebase.functions()
export const cloudFunction = firebase.app().functions("asia-south1");
firebase.auth().languageCode = "en";
// cloudFunction.useEmulator("localhost", 5001)

export const signInWithId = (email) => {
  return new Promise(async (res, rej) => {
    try {
      const cred = await auth.signInWithEmailAndPassword(
        email,
        "g20virtual@123"
      );
      res(cred.user);
      // addLoginAnalytics(cred.user)
    } catch (error) {
      rej(error);
    }
  });
};
const defaultImageUrl =
  "https://firebasestorage.googleapis.com/v0/b/djfarmademo.appspot.com/o/profileimages%2Fblank-avatar.png?alt=media&token=2af15226-9bd7-47ce-bc72-f3c1a12a0780";

export const signUpWithId = (name, email) => {
  return new Promise(async (res, rej) => {
    try {
      const response = await auth.createUserWithEmailAndPassword(
        email,
        `g20virtual@123`
      );
      let obj = {
        displayName: name,
      };

      await response.user.updateProfile(obj);
      // addLoginAnalytics(response.user)
      // let sendEmail = cloudFunction.httpsCallable("sendEmailOnRegisteration");
      // sendEmail(obj);

      res(response.user);
    } catch (error) {
      console.log(error, "error");
      rej(error);
    }
  });
};

export const loadUser = async (
  userEmail,
  password,
  name,
  isEmail,
  email,
  cb,
  forceCreateNew = true
) => {
  return new Promise(async (res, rej) => {
    try {
      if (isEmail) {
        await signInWithId(email, password);
      } else {
        let r = await firebasePhoneAuthCodeSent(email);
        if (r) {
          cb(true);
        }
      }
      res();
    } catch (err) {
      console.log(err.code);
      if (err.code === "auth/user-not-found" && forceCreateNew) {
        try {
          // await signUpWithId(userEmail, password, name, "", "", isEmail, email);
          // let res = await sendOTP(userEmail, isEmail);
          // cb(res.data);
          // if no user found
          cb(true);
          res();
        } catch (error) {
          console.log(error);
          if (error.code === "NoUserFound") {
            // rej(error);
          }
        }
      }
      rej({
        code: err.code,
        message: err.message,
      });
    }
  });
};

export const firebasePhoneAuthCodeSent = async (_phoneNumber) => {
  const phoneNumber = _phoneNumber;
  const appVerifier = window.recaptchaVerifier;

  try {
    let confirmationResult = await firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier);

    // SMS sent. Prompt user to type the code from the message, then sign the
    // user in with confirmationResult.confirm(code).
    window.confirmationResult = confirmationResult;
    return true;
  } catch (error) {
    // Error; SMS not sent
    console.error(error);
    throw error;
  }
};
export const signOut = (noRefresh) => {
  auth.signOut().then(function () {
    localStorage.removeItem("user");
    // window.open("https://" + document.domain, "_self");
    if (!noRefresh) {
      window.location.reload();
      window.location.href = "/";
    }
  });
};

export const sendOTP = async (email, isEmail) => {
  try {
    if (isEmail) {
      const cloudRef = cloudFunction.httpsCallable("sendOTPEmail");
      let res = await cloudRef({ email: email });
      return res;
    } else {
      //@TODO: send otp on number
      // const cloudRef = cloudFunction.httpsCallable("sendOTPSMS");
      // let res = await cloudRef({ email: email });
      // return res;
    }
  } catch (error) {
    console.error(error);
  }
};
export const verifyOTP = async (props) => {
  try {
    const { isEmail } = props;

    if (isEmail) {
      const cloudRef = cloudFunction.httpsCallable("verifyOTPEmail");
      let res = await cloudRef(props);
      return res;
    } else {
      // const cloudRef = cloudFunction.httpsCallable("verifyOTPSMS");
      // let res = await cloudRef(props);
      // return res;
    }
  } catch (error) {
    console.error(error);
  }
};

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const generatePushID = (function () {
  // Modeled after base64 web-safe chars, but ordered by ASCII.
  var PUSH_CHARS =
    "-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz";

  // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
  var lastPushTime = 0;

  // We generate 72-bits of randomness which get turned into 12 characters and appended to the
  // timestamp to prevent collisions with other clients.  We store the last characters we
  // generated because in the event of a collision, we'll use those same characters except
  // "incremented" by one.
  var lastRandChars = [];

  return function () {
    var now = new Date().getTime();
    var duplicateTime = now === lastPushTime;
    lastPushTime = now;

    var timeStampChars = new Array(8);
    for (var i = 7; i >= 0; i--) {
      timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
      // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
      now = Math.floor(now / 64);
    }
    if (now !== 0)
      throw new Error("We should have converted the entire timestamp.");

    var id = timeStampChars.join("");

    if (!duplicateTime) {
      for (i = 0; i < 12; i++) {
        lastRandChars[i] = Math.floor(Math.random() * 64);
      }
    } else {
      // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
      for (i = 11; i >= 0 && lastRandChars[i] === 63; i--) {
        lastRandChars[i] = 0;
      }
      lastRandChars[i]++;
    }
    for (i = 0; i < 12; i++) {
      id += PUSH_CHARS.charAt(lastRandChars[i]);
    }
    if (id.length != 20) throw new Error("Length should be 20.");

    return id;
  };
})();

export const youtube_parser = (url) => {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
};

// R1Rcm
export const getUserDetails = (uid) => {
  return new Promise(async (resolve, reject) => {
    try {
      let path = `/users/${uid}`;
      var dbref = realDB.ref(path);
      // var dbref = realDB.ref(path).orderByChild('email').startAt(email.toUpperCase()).endAt(email.toLowerCase()+'\uf8ff');
      const query = await dbref.once("value");
      if (query.exists()) {
        let key = Object.keys(query.val())[0];
        resolve(query.val());
      } else {
        reject({
          code: "NoUserFound",
          message: "No Such User Is Registered",
        });
      }
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};

export const turnOnUserCard = (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      let path = "/users";
      var dbref = realDB
        .ref(path)
        .orderByChild("email")
        .equalTo(email)
        .limitToFirst(1);
      // var dbref = realDB.ref(path).orderByChild('email').startAt(email.toUpperCase()).endAt(email.toLowerCase()+'\uf8ff').limitToFirst(1);
      const query = await dbref.once("value");
      if (query.exists()) {
        let key = Object.keys(query.val())[0];

        if (!query.val()[key].enabled) {
          realDB.ref(`/users/${key}`).update({
            enabled: true,
          });
        }
        resolve();
      } else {
        reject({
          code: "NoCardUserFound",
          message: "No Such User Is Registered",
        });
      }
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};

export const attachConnectProfilesListener = (callback) => {
  let path = "/users";
  window.dbref = realDB.ref(path).orderByChild("enabled").equalTo(true);
  window.connectListener = window.dbref.on(
    "value",
    (query) => {
      if (query.exists()) {
        callback(false, query.val());
      } else {
        callback(true, {
          code: "NoUserFound",
          message: "NoUserIsFoundForConnect",
        });
      }
    },
    (err) => {
      callback(true, err);
    }
  );
};

export const removeConnectProfilesListener = () => {
  window.dbref.off("value", window.connectListener);
};

//#region Login analytics

const getUserIdForAnalytics = (user, getEmail = true) => {
  return new Promise((resolve, reject) => {
    try {
      if (user.email) {
        let Id = "";
        Id = !getEmail ? user.email.split("@")[0] : user.email;
        Id = Id.replace(/[&\/\\#,+$~%.'":*?<>{}]/g, "");
        Id = Id.toLowerCase();
        resolve(Id);
      } else {
        resolve(user.uid);
        // let err = {
        //   code: "NoEmailFound",
        //   message: "Please pass a valid User Object",
        // };
        // throw err;
      }
    } catch (error) {
      reject(error);
    }
  });
};

const checkForTimeStampAvailable = () => {
  return new Promise(async (res, rej) => {
    try {
      if (
        window.todaysDateTimestamp === undefined ||
        window.todaysDateTimestamp === null
      ) {
        const result = await getCurrentTimeStampV2();
        window.todaysDateTimestamp = result.todaysDateTimestamp;
        res();
        return;
      }
      res();
    } catch (error) {
      rej(error);
    }
  });
};

export const getCurrentTimeStampV2 = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch("https://dj-timeserver.glitch.me");
      const result = await res.json();
      var currDateWeb = new Date(result.epoch);
      var currDateLocal = new Date();
      var webTimeDiff = currDateWeb.getTime() - currDateLocal.getTime();
      let todaysDateTimestamp = `${currDateWeb.getFullYear()}-${currDateWeb.getMonth() + 1
        }-${currDateWeb.getDate()}`;
      resolve({ currDateWeb, webTimeDiff, todaysDateTimestamp });
    } catch (err) {
      reject(err);
    }
  });
};

export const addLoginAnalytics = (user) => {
  console.log(user, "user");
  return new Promise(async (res, rej) => {
    try {
      await checkForTimeStampAvailable();
      let Id = getSessionId(); // await getUserIdForAnalytics(user);

      // let path = `analytics_VCC/${window.todaysDateTimestamp}/${Id}`;
      let path = `analytics_VCC/${Id}`;
      const reference = realDB.ref(path);

      const snapShot = await reference.once("value");

      var details = {
        email: user.email,
        name: user.displayName ? user.displayName : user?.name,
        isMobile: isMobile,
        browserName: browserName,
        OS: osName,
        uid: user?.uid
      };

      if (window.userData) {
        details = {
          ...details,
          ...window.userData,
        };
      }

      if (snapShot.exists()) {
        await reference.update({
          ...details,
          lastLoginTime: firebase.database.ServerValue.TIMESTAMP,
        });
      } else {
        await reference.set({
          ...details,
          firstLoginTime: firebase.database.ServerValue.TIMESTAMP,
          lastLoginTime: firebase.database.ServerValue.TIMESTAMP,
        });
      }
      res();
    } catch (error) {
      console.log(error);
      // if (error === "TypeError: Failed to fetch") {
      //   window.alert(
      //     "Please check your internet conenect and refresh the page."
      //   );
      // }
      rej(error);
    }
  });
};

let diconnectReference = null;
export const addDisconnectListener = (user) => {
  return new Promise(async (res, rej) => {
    try {
      await checkForTimeStampAvailable();
      let Id = getSessionId(); // await getUserIdForAnalytics(user);
      let path = `analytics_VCC/${Id}`;
      // let path = `analytics_VCC/${window.todaysDateTimestamp}/${Id}`;
      const reference = realDB.ref(path);
      diconnectReference = reference.onDisconnect();
      await diconnectReference.update({
        lastLogoutTime: firebase.database.ServerValue.TIMESTAMP,
      });
      res();
    } catch (error) {
      rej(error);
    }
  });
};

export const addLogoutAnalytics = (user) => {
  return new Promise(async (res, rej) => {
    try {
      await checkForTimeStampAvailable();
      let Id = getSessionId(); //await getUserIdForAnalytics(user);

      // let path = `analytics_VCC/${window.todaysDateTimestamp}/${Id}`;
      let path = `analytics_VCC/${Id}`;
      const reference = realDB.ref(path);

      await reference.update({
        lastLogoutTime: firebase.database.ServerValue.TIMESTAMP,
      });

      if (diconnectReference) {
        diconnectReference.cancel();
      }

      res();
    } catch (error) {
      rej(error);
    }
  });
};

var sessionId = null;

export const updateUserStatus = (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (sessionId == null) {
        sessionId = userId + "_" + new Date().getTime();
      }
      var userCheck = realDB.ref("loggedInUser/" + sessionId);
      await userCheck.update({
        state: "online",
        lastChange: firebase.database.ServerValue.TIMESTAMP,
      });
      let disconnectRef = await userCheck.onDisconnect();
      await disconnectRef.remove();
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

//#endregion

//#region Analytics

export const attachMyLikeListner = (userid, cb = () => { }) => {
  try {
    realDB.ref("userLike/" + userid).on("value", (snapShot) => {
      if (snapShot.exists()) {
        let snapShotVal = snapShot.val();
        cb(snapShotVal);
      }
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateLikeCount = async (userid, id, like) => {
  return new Promise(async (res, rej) => {
    try {
      const doc = await realDB.ref("like/");
      await doc.update({
        [id]: firebase.database.ServerValue.increment(like ? 1 : -1),
      });
      await realDB.ref("userLike/" + userid).update({
        [id]: like ? 1 : -1,
      });
      res();
    } catch (error) {
      rej(error);
    }
  });
};

export const attachLikeListner = (cb = () => { }) => {
  try {
    realDB.ref("like").on("value", (snapShot) => {
      if (snapShot.exists()) {
        let snapShotVal = snapShot.val();
        cb(snapShotVal);
      }
    });
  } catch (error) {
    console.error(error);
  }
};

export const attachLocationListner = (cb = () => { }) => {
  try {
    realDB.ref("loggedInUser").on("value", (snapShot) => {
      if (snapShot.exists()) {
        let snapShotVal = snapShot.val();
        let Obj = {};
        Object.values(snapShotVal).forEach((sessionData) => {
          let location = sessionData.location;
          if (location)
            if (Obj[location]) {
              Obj[location] += 1;
            } else {
              Obj[location] = 1;
            }
        });
        cb(Obj);
      }
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateUserLocation = (user, location) => {
  return new Promise(async (resolve, reject) => {
    try {
      let userId = await getUserIdForAnalytics(user);
      if (sessionId == null) {
        sessionId = userId + "_" + new Date().getTime();
      }
      var userCheck = realDB.ref("loggedInUser/" + sessionId);
      await userCheck.update({
        location: location,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};
let _savedSessionId = null;

const getSessionId = () => {
  if (!_savedSessionId) {
    _savedSessionId = window.sessionStorage.getItem("sId");
    if (!_savedSessionId) {
      _savedSessionId = uniqid();
      window.sessionStorage.setItem("sId", _savedSessionId);
    }
  }
  return _savedSessionId;
};

export const addRealtimeHotspotAnalytics = (
  user,
  hotspotName,
  stallHotspots = false
) => {
  return new Promise(async (res, rej) => {
    try {
      await checkForTimeStampAvailable();
      let Id = getSessionId(); //await getUserIdForAnalytics(user);
      console.log(Id);
      // let path =  `analytics_VCC/${window.todaysDateTimestamp}/${Id}`;
      let path = `analytics_VCC/${Id}`;

      const reference = realDB.ref(path);
      console.log(reference, "referenmce");
      await reference.update({
        ...user,
        [hotspotName]: firebase.database.ServerValue.increment(1),
      });
      res();
    } catch (error) {
      rej(error);
    }
  });
};

export const addGAAnalytics = (eventName, data = {}) => {
  analytics.logEvent(eventName, data);
};

export const addCustomRealtimeHotspotAnalytics = (user, id) => {
  return new Promise(async (res, rej) => {
    try {
      console.log("addCustomRealtimeHotspotAnalytics");
      await checkForTimeStampAvailable();
      let trimId = id.trim();
      let Id = getSessionId(); // await getUserIdForAnalytics(user);
      // let path = `${details.path}/analytics_VCC/${window.todaysDateTimestamp}/${Id}`;
      // let path = `/analytics_VCC/${window.todaysDateTimestamp}/${Id}`;
      let path = `/analytics_VCC/${Id}`;
      const reference = realDB.ref(path);

      let userDetails = {
        uid: user?.uid,
      };
      if (window.userData) {
        userDetails = {
          ...userDetails,
          ...window.userData,
        };
      }
      addGAAnalytics("hotspot_click", { hotpsotName: trimId, uid: user?.uid })
      await reference.update({
        ...userDetails,
        [trimId]: firebase.database.ServerValue.increment(1),
      });
      res();
    } catch (error) {
      rej(error);
    }
  });
};

//#endregion

// export const getLinkListener = (callback) => {
//     window.audiListener = firestore
//         .collection(AppString.BACKSATGE)
//         .doc(AppString.BACKSTAGELINK)
//         .onSnapshot(
//             (doc) => {
//                 if (!doc.exists) {
//                     callback({
//                         code: "NoDataFound",
//                         message: "no such document exists.",
//                     });
//                 }
//                 callback(null, doc.data());
//             },
//             (err) => {
//                 callback(err);
//             }
//         );
// };

// export const getCommonListener = (callback) => {
//     window.audiListener = firestore
//         .collection(AppString.BACKSATGE)
//         .doc(AppString.BACKSTAGECOMMON)
//         .onSnapshot(
//             (doc) => {
//                 if (!doc.exists) {
//                     callback({
//                         code: "NoDataFound",
//                         message: "no such document exists.",
//                     });
//                 }
//                 callback(null, doc.data());
//             },
//             (err) => {
//                 callback(err);
//             }
//         );
// };

//Live count function section

const UserState = {
  online: "online",
  offline: "offline",
};

export const liveCountListener = (callback) => {
  realDB.ref("loggedInUser_VCC").on(
    "value",
    (query) => {
      if (query.exists() && callback) {
        const data = query.val();
        let locationCount = {
          online: 0,
          lobby: 0,
          audi: 0,
          networking: 0,
          speakers: 0,
          rencen: 0,
          exibooth: 0,
        };

        Object.keys(data).forEach((userId) => {
          if (data[userId].state === UserState.online) {
            locationCount[data[userId].location] += 1;
            locationCount.online += 1;
          }
        });

        callback(locationCount);
      }
    },
    (err) => {
      if (callback) {
        callback(null, err);
      }
    }
  );
};

//---------------------------

export const addUserDetails = (userId, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      var ref = realDB.ref("users/" + userId);
      await ref.set({
        ...data,
        timestamp: firebase.database.ServerValue.TIMESTAMP,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const addUserFeedback = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      var _currDate = new Date().getTime();

      var ref = realDB.ref(`userFeedbacks/${data.uid}_${_currDate}`);
      await ref.set({
        ...data,
        timestamp: firebase.database.ServerValue.TIMESTAMP,
      });
      resolve(_currDate);
    } catch (error) {
      reject(error);
    }
  });
};

export const getUserFeedbacks = async ({ limit = 10 }) => {
  try {
    let path = "/userFeedbacks/";
    let dbref = realDB.ref(path).orderByChild("timestamp").limitToLast(limit);
    let query = await dbref.once("value");
    let _data = [];
    if (!query.exists()) {
      return _data;
    }
    query.forEach((e) => {
      _data.push(e.val());
    });
    let sortedArray = _data.sort((a, b) => b.timestamp - a.timestamp);
    return sortedArray;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getUserFeedbacksDataPaginated = (
  { limit = 10, data = [], lastDocRef = { lastTimestamp: null, key: null } },
  cb = () => console.log("no data found")
) => {
  try {
    let path = "/userFeedbacks/";
    let dbref = null;
    if (lastDocRef.lastTimestamp && lastDocRef.key) {
      dbref = realDB
        .ref(path)
        .orderByChild("timestamp")
        .endBefore(lastDocRef.lastTimestamp, lastDocRef.key)
        .limitToLast(limit);
    } else {
      dbref = realDB.ref(path).orderByChild("timestamp").limitToLast(limit);
    }

    dbref.on("value", (query) => {
      let _data = [];
      if (query.exists()) {
        query.forEach((e) => {
          let _d = e.val();
          _data.push({ ..._d, key: e.key });
        });
        // sort in descending order
        let sortedArray = _data.sort((a, b) => b.timestamp - a.timestamp);

        cb({
          data: [...data, ...sortedArray],
          lastDocRef: {
            lastTimestamp:
              sortedArray.length > limit
                ? sortedArray[sortedArray.length - 1].timestamp
                : null,
            key:
              sortedArray.length > limit
                ? sortedArray[sortedArray.length - 1].key
                : null,
          },
        });
      } else {
        cb({
          data: data,
          lastDocRef: { lastTimestamp: null, key: null },
        });
      }
    });
  } catch (err) {
    cb({ data: [], lastDocRef: { lastTimestamp: null, key: null } });
    console.error(err);
  }
};

export const addHotspotAnalytics = (user, hotspotName) => {
  if (hotspotName && user) {
    let _path = "Analytics" + "/" + getSessionId();

    let _nodeRef = realDB.ref(_path);
    _nodeRef.update({
      [hotspotName]: firebase.database.ServerValue.increment(1),
    });
  } else {
    console.error("user or hotspotName is missing, analytics failed");
  }
};

export const getCountDownData = (cb = () => { }) => {
  try {
    realDB.ref(`/staticData/countdown`).on("value", (snapShot) => {
      if (snapShot.exists()) {
        let snapShotVal = snapShot.val();
        cb(snapShotVal);
      } else {
        cb(null)
      }
    });
  } catch (error) {
    console.error(error);
    cb(null)
  }
};

export const updateCountDownData = (data = {}) => {
  try {
    let _nodeRef = realDB.ref(`/staticData/countdown`);
    _nodeRef.update({ ...data });
  } catch (error) {
    console.error(error);
  }
};
